import {ChangeDetectorRef, Component, HostBinding, HostListener, Input, ViewEncapsulation} from '@angular/core';
import {WindowLayoutComponent} from "../window-layout/window-layout.component";
import {WindowPosition} from "../../api/models/window.model";
import {WindowInfo} from "../window-info";
import {WindowManagerService} from "../window-manager.service";
import {WindowComponentImpl} from "../window-component-impl";
import {asyncScheduler} from "rxjs";

const BASE_ZINDEX = 1000

@Component({
  selector: 'app-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WindowComponent {
  static readonly CLASSES = Object.freeze('d-flex-column fill position-relative'.split(' '))

  @Input('window') windowInfo!: WindowInfo

  private _component?: WindowComponentImpl

  private _moving: boolean = false
  private _resizing: boolean = false

  // private moveOffset: Position = {x: 0, y: 0}
  private gridArea: string = '1 / 1 / 1 / 1'
  private zIndex: number = BASE_ZINDEX

  constructor(
    readonly layout: WindowLayoutComponent,
    readonly windowManager: WindowManagerService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
  }

  get component() {
    return this._component
  }

  setComponent(value: WindowComponentImpl) {
    // Prevent state from changing while Angular is rendering changes
    asyncScheduler.schedule(() => {
      this._component = value
    })
  }

  @HostBinding('attr.class')
  get windowClasses() {
    const classes = Array.from(WindowComponent.CLASSES)
    if (this.docked) {
      classes.push('window-docked', `window-${this.position.replaceAll('_', '-')}`)
    } else {
      classes.push(`window-${this.position}`)
    }
    if (this.windowInfo.id === this.windowManager.active) {
      classes.push('active')
    }
    return classes.join(' ')
  }

  get window() {
    return this.windowInfo.window
  }

  get position() {
    return this.window.position
  }

  get docked() {
    return this.position.startsWith('dock')
  }

  activate() {
    this.windowManager.setActive(this.windowInfo)
  }

  get active() {
    return this.window.order === 0
  }

  get gridMode() {
    return this.layout.layout === 'grid'
  }

  get moving() {
    return this._moving
  }

  set moving(val: boolean) {
    this._moving = val
    this.changeDetector.markForCheck()
  }

  get resizing() {
    return this._resizing
  }

  set resizing(val: boolean) {
    this._resizing = val
    this.changeDetector.markForCheck()
  }

  @HostBinding('class')
  get classes() {
    return {
      'grid-mode': this.gridMode,
      active: this.active,
      resizing: this.resizing,
      moving: this.moving,
      'd-none': this.window.position === WindowPosition.Hidden
    }
  }

  @HostBinding('style')
  get styles() {
    if (this.gridMode) {
      return {
        zIndex: this.zIndex.toString(),
        gridArea: this.gridArea
      }
    }
    return {}
  }

  @HostListener('click')
  onClick() {
    this.activate()
  }

  onPointerDown($event: PointerEvent, context: 'move' | 'resize') {
    // if ($event.buttons === 1 && this.gridMode) {
    //   this.el.nativeElement.setPointerCapture($event.pointerId)
    //   this.activate()
    //
    //   if (context === 'resize') {
    //     this.resizing = true
    //   } else {
    //     const rect = this.el.nativeElement.getBoundingClientRect()
    //     this.moving = true
    //     this.moveOffset = {
    //       x: $event.clientX - rect.x,
    //       y: $event.clientY - rect.y
    //     }
    //   }
    // }
  }

  // @HostListener('pointermove', ['$event'])
  // onPointerMove($event: PointerEvent) {
  //   if (this.resizing) {
  //     this.resizeToPointer($event)
  //   } else if (this.moving) {
  //     this.moveToPointer($event)
  //   }
  // }
  //
  // @HostListener('pointerup', ['$event'])
  // onPointerUp($event: PointerEvent) {
  //   if ($event && (this.resizing || this.moving)) {
  //     this.el.nativeElement.releasePointerCapture($event.pointerId)
  //   }
  // }
  //
  // @HostListener('lostpointercapture')
  // onPointerLostCapture() {
  //   this.resizing = false
  //   this.moving = false
  // }

  // private updateStyle(state: UpdateWindowModel = this.state) {
  //   if (state.width !== 0) {
  //     asyncScheduler.schedule(() => {
  //       this.gridArea = `${state.y + 1} / ${state.x + 1} / span ${state.height} / span ${state.width}`
  //       this.zIndex = BASE_ZINDEX - state.order
  //       this.changeDetector.markForCheck()
  //     })
  //   }
  // }
  //
  // private moveToPointer($event: PointerEvent) {
  //   const pos = this.layout.getGridPosition({
  //     x: $event.clientX - this.moveOffset!.x + 5,
  //     y: $event.clientY - this.moveOffset!.y + 5
  //   })
  //
  //   if (pos) {
  //     const x = Math.max(0, Math.min(pos.x, this.layout.GRID_WIDTH - this.state.width))
  //     const y = Math.max(0, Math.min(pos.y, this.layout.GRID_HEIGHT - this.state.height))
  //
  //     if (x !== this.state.x || y !== this.state.y) {
  //       // console.debug(`Moving ${this.app} to ${x}, ${y}`)
  //
  //       this.changeDetector.markForCheck()
  //       this.windowManager.update(Object.assign(this._state!, {x, y}) as UpdateWindowModel)
  //       this.updateStyle()
  //     }
  //   }
  // }
  //
  // private resizeToPointer($event: PointerEvent) {
  //   const pos = this.layout.getPointerGridPosition($event)
  //   if (pos) {
  //     const width = Math.max(pos.x - this.state.x + 1, this.layout.WINDOW_MIN_SIZE)
  //     const height = Math.max(pos.y - this.state.y + 1, this.layout.WINDOW_MIN_SIZE)
  //
  //     if (width !== this.state.width || height !== this.state.height) {
  //       // console.debug(`Resizing ${this.app} to ${width}, ${height}`)
  //
  //       this.changeDetector.markForCheck()
  //       this.windowManager.update(Object.assign(this._state!, {width, height}) as UpdateWindowModel)
  //       this.updateStyle()
  //     }
  //   }
  // }
}
