import {Component, Input} from '@angular/core';
import {ModalComponent} from "../../common/modal/modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateRoomBody, RoomService} from "../room.service";
import {ErrorService} from "../../common/error.service";
import {ModalConfig} from "../../common/modal/modal";
import {ColorStyle} from "../../common/types";
import {map} from "rxjs";
import {RoomTypeDef} from "../room-type";

@Component({
  selector: 'app-new-room',
  templateUrl: './new-room.component.html',
  styles: [
  ]
})
export class NewRoomComponent extends ModalComponent {
  static readonly modalConfig: ModalConfig = {
    title: 'New Room',
    buttons: [
      {text: 'Start', style: ColorStyle.Success, action: 'start'},
      'cancel'
    ]
  }

  // readonly roomTypes = RoomTypes

  readonly newRoomForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20)
    ]),
    type: new FormControl('conference', [
      Validators.required
    ])
  })

  private _roomType!: RoomTypeDef

  constructor(
    readonly rooms: RoomService,
    readonly error: ErrorService
  ) {
    super()
  }

  override get title() {
    return `New ${this.roomType.name} Room`
  }

  get roomType() {
    return this._roomType
  }

  @Input('roomType')
  set roomType(roomTypeDef: RoomTypeDef) {
    this._roomType = roomTypeDef
    this.newRoomForm.patchValue({ type: roomTypeDef.type })
  }

  newRoom() {
    this.newRoomForm.disable()
    return this.error.handleObservable(
      this.rooms.create(this.newRoomForm.value as CreateRoomBody),
      {
        errorHandler: () => this.newRoomForm.enable(),
        successHandler: room => this.close(room)
      }
    )
  }

  override canClick(action: any): boolean {
    if (action === 'start') {
      return this.newRoomForm.valid
    }
    return true
  }

  override onButtonClick(action: any) {
    if (action === 'start') {
      return this.newRoom().pipe(map(() => false))
    }
    return false
  }
}
