import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {WindowLayoutComponent} from './windows/window-layout/window-layout.component';
import {WindowComponent} from './windows/window/window.component';
import {RoomComponent} from './room/room/room.component';
import {IconComponent} from './common/icon/icon.component';
import {ButtonComponent} from './common/button/button.component';
import {NgOptimizedImage} from "@angular/common";
import {RoomHostControlsComponent} from './room/room-host-controls/room-host-controls.component';
import {RoomSideBarComponent} from './room/room-side-bar/room-side-bar.component';
import {VideoWindowComponent} from './video/video-window/video-window.component';
import {MediaManagerComponent} from './media-manager/media-manager.component';
import {HomeComponent} from './home/home/home.component';
import {HttpClientModule} from "@angular/common/http";
import {AudioWindowComponent} from './audio/audio-window/audio-window.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PlaceholderWindowComponent} from './common/placeholder-window/placeholder-window.component';
import {DevicesComponent} from './devices/devices/devices.component';
import {ConferenceWindowComponent} from './conference/conference-window/conference-window.component';
import {ConferenceParticipantComponent} from './conference/conference-participant/conference-participant.component';
import {ToasterComponent} from './common/toaster/toaster.component';
import {ShareWindowComponent} from './conference/share-window/share-window.component';
import {VideoCanvasComponent} from './common/video-canvas/video-canvas.component';
import {ShareWindowItemComponent} from './conference/share-window-item/share-window-item.component';
import {EmailNamePipe} from './common/email-name.pipe';
import {AutoGridZoomedDirective} from "./common/auto-grid/auto-grid-zoomed.directive";
import {InviteRoomUserModalComponent} from './room/invite-room-user-modal/invite-room-user-modal.component';
import {ModalHostComponent} from './common/modal/modal-host/modal-host.component';
import {ModalWindowComponent} from './common/modal/modal-window/modal-window.component';
import {AppBarComponent} from './app-bar/app-bar.component';
import {NewRoomComponent} from './room/new-room/new-room.component';
import {AutoGridComponent} from "./common/auto-grid/auto-grid.component";
import {ErrorModalComponent} from './common/modal/error-modal/error-modal.component';
import {ModalFocusDirective} from './common/modal/modal-focus.directive';
import {ConferenceGalleryComponent} from './conference/conference-gallery/conference-gallery.component';
import {
  ConferenceFeatureButtonsComponent
} from './conference/conference-feature-buttons/conference-feature-buttons.component';
import {DropDownComponent} from './common/drop-down/drop-down.component';
import {IfLocalDirective} from "./common/if-local.directive";
import {ScreenMaskComponent} from './common/screen-mask/screen-mask.component';
import {AnnotationsComponent} from './annotations/annotations/annotations.component';
import {DrawingComponent} from "./annotations/drawing.component";
import { DrawingTargetDirective } from './annotations/drawing-target.directive';
import { TestDrawingComponent } from './test/test-drawing/test-drawing.component';
import { FullScreenDirective } from './common/full-screen.directive';
import { NewRoomButtonComponent } from './room/new-room-button/new-room-button.component';
import { TestDevicesComponent } from './test/test-devices/test-devices.component';

@NgModule({
  declarations: [
    AppComponent,
    WindowLayoutComponent,
    WindowComponent,
    RoomComponent,
    IconComponent,
    ButtonComponent,
    RoomHostControlsComponent,
    RoomSideBarComponent,
    VideoWindowComponent,
    MediaManagerComponent,
    HomeComponent,
    AudioWindowComponent,
    PlaceholderWindowComponent,
    DevicesComponent,
    ConferenceWindowComponent,
    ConferenceParticipantComponent,
    ToasterComponent,
    ShareWindowComponent,
    AutoGridComponent,
    AutoGridZoomedDirective,
    VideoCanvasComponent,
    ShareWindowItemComponent,
    EmailNamePipe,
    InviteRoomUserModalComponent,
    ModalHostComponent,
    ModalWindowComponent,
    AppBarComponent,
    NewRoomComponent,
    ErrorModalComponent,
    ModalFocusDirective,
    ConferenceGalleryComponent,
    ConferenceFeatureButtonsComponent,
    DropDownComponent,
    ScreenMaskComponent,
    AnnotationsComponent,
    DrawingComponent,
    DrawingTargetDirective,
    TestDrawingComponent,
    FullScreenDirective,
    NewRoomButtonComponent,
    TestDevicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgOptimizedImage,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IfLocalDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
