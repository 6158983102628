import {Injectable, Injector} from '@angular/core';
import {ConferenceService} from "./conference.service";
import {ShareWindowComponent} from "./share-window/share-window.component";
import {Subscriptions} from "../common/subscriptions";
import {map, merge} from "rxjs";
import {ObservableList} from "../common/observable-list";
import {ScreenCapture} from "./screen-capture";

@Injectable({
  providedIn: 'root'
})
export class ShareManagerService {
  private _shareWindow?: ShareWindowComponent
  readonly captures = new ObservableList<ScreenCapture>()
  private subscriptions = new Subscriptions()

  readonly changed = merge(
    this.captures.changed,
    this.shared.changed
  ).pipe(
    map(() => this.captures.length + this.shared.length)
  )

  constructor(
    private readonly conference: ConferenceService,
    private readonly injector: Injector
  ) { }

  get shared() {
    return this.conference.screens
  }

  get shareWindow() {
    return this._shareWindow
  }

  setShareWindow(window: ShareWindowComponent) {
    this._shareWindow = window
  }

  capture() {
    // 1. Capture screen/window
    // 2. Add record that creates a capture view
    // 3. Wait for the view to publish a video stream
    // 4. Publish the stream to the conference
    const capture = new ScreenCapture(this.injector)

    this.subscriptions.add(capture.start(), () => {
      this.captures.add(capture)
      this.subscriptions.add(capture.ended, () => {
        this.captures.remove(capture)
      })
    })
  }

  reset() {
    this.captures.forEach(capture => capture.close())
    delete this._shareWindow
  }
}
