<form [formGroup]="newRoomForm" (ngSubmit)="newRoom()" class="d-flex-column gap-3 align-items-center">
  <div class="input-group input-group-lg">
    <input
      formControlName="name"
      modal-focus
      type="text"
      id="newRoomName"
      class="form-control"
      placeholder="New room name"
    >
  </div>
</form>
