import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {EntityList, EntityModel} from "./models/entity.model";
import {Observable, share} from "rxjs";
import {Environment} from "../config/environment";
import {hostWithProtocol} from "../common/utilities";

interface ApiGetParams {
  path?: string
  query?: {[q: string]: string}
}

interface ApiPostParams<BodyT = undefined> extends ApiGetParams {
  body?: BodyT
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  static readonly BASE_URL = hostWithProtocol(Environment.api.host)
  static readonly API_PATH_PREFIX = `v${Environment.api.version}/`

  constructor(
    private readonly http: HttpClient
  ) { }

  static urlFor(pathAndQuery: string): string {
    return `${ApiService.BASE_URL}/${pathAndQuery}`
  }

  urlFor(controller: string, path?: string): string {
    return ApiService.urlFor(`${ApiService.API_PATH_PREFIX}${controller}${path ? '/' : ''}${path || ''}`)
  }

  list<T extends EntityModel, LT extends EntityList<T> = EntityList<T>>(
    controller: string,
    params: ApiGetParams = {}
  ): Observable<LT> {
    return this.http.get<LT>(
      this.urlFor(controller, params.path),
      {params: new HttpParams(params.query), withCredentials: true}
    ).pipe(share())
  }

  get<T extends EntityModel>(controller: string, params: ApiGetParams = {}): Observable<T> {
    return this.http.get<T>(
      this.urlFor(controller, params.path),
      {params: new HttpParams(params.query), withCredentials: true}
    ).pipe(share())
  }

  post<T extends EntityModel, BodyT = undefined>(controller: string, params: ApiPostParams<BodyT> = {}): Observable<T> {
    return this.http.post<T>(
      this.urlFor(controller, params.path),
      params.body,
      {params: new HttpParams(params.query), withCredentials: true}
    ).pipe(share())
  }

  delete<T extends EntityModel>(controller: string, params: ApiGetParams = {}): Observable<T> {
    return this.http.delete<T>(
      this.urlFor(controller, params.path),
      {params: new HttpParams(params.query), withCredentials: true}
    ).pipe(share())
  }
}
