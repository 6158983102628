import {ModalWindowComponent} from "./modal-window/modal-window.component";
import {inject, Injectable} from "@angular/core";
import {ModalFocusDirective} from "./modal-focus.directive";
import {Observable} from "rxjs";

/**
 * Base class for all modal
 */
@Injectable()
export class ModalComponent {
  private readonly modalWindow = inject(ModalWindowComponent)

  focusChild?: ModalFocusDirective

  constructor() {
    this.modalWindow.setComponent(this)
  }

  /**
   * Override in modal implementations to provide a dynamic/input-based title
   */
  get title(): string | undefined {
    return undefined
  }

  /**
   * Override in modal implementations to conditionally disable modal buttons
   * @param action Button action
   */
  canClick(action: any) {
    return true
  }

  /**
   * Override in modal implementations to handle button actions.
   * @param action Button action that user clicked
   * @return result Either a boolean or observable that results in a boolean. If the result
   *  is truthy then the modal will be closed. For asynchronous handlers using an observable,
   *  the modal buttons will be disabled until the observable finishes. Implementations
   *  should ensure that other modal-specific controls are disabled if needed.
   */
  onButtonClick(action: any): boolean | Observable<boolean> {
    return false
  }

  protected get modal() {
    return this.modalWindow.modal
  }

  protected close(result?: any) {
    this.modal.close(result)
  }

}
