import {DeviceService} from "../devices/device.service";
import {ConferenceService} from "./conference.service";
import {AcquiredDevices} from "../devices/acquired-devices";
import {BehaviorSubject, map} from "rxjs";
import {Injector} from "@angular/core";
import {ConferenceFeature} from "../features/conference.feature";
import {Subscriptions} from "../common/subscriptions";
import {IDevices} from "../devices/IDevices";
import {ErrorService} from "../common/error.service";
import {Logger} from "../common/logger";
import {Logging} from "../common/logging";

/**
 * This is used by multiple UI components and services to
 * manage the state of a screen share
 */
export class ScreenCapture implements IDevices {
  /**
   * The captured screen/window
   */
  private _devices?: AcquiredDevices
  /**
   * The capture video stream
   */
  private readonly captureSubject = new BehaviorSubject<MediaStream | undefined>(undefined)
  /**
   * The output video stream. In the case of a secure conference, this is the
   * stream from the obfuscated video canvas. Otherwise it is the same as the
   * source capture stream.
   */
  private readonly outputSubject = new BehaviorSubject<MediaStream | undefined>(undefined)
  private readonly deviceService: DeviceService
  private readonly conference: ConferenceService
  private readonly feature: ConferenceFeature
  private readonly errorService: ErrorService
  private readonly subscriptions = new Subscriptions()
  private readonly logger = Logging.for('conference', () => `screen-share:${this.id}`)

  readonly capture = this.captureSubject.asObservable()
  readonly output = this.outputSubject.asObservable()

  constructor(injector: Injector) {
    this.deviceService = injector.get(DeviceService)
    this.conference = injector.get(ConferenceService)
    this.feature = injector.get(ConferenceFeature)
    this.errorService = injector.get(ErrorService)
  }

  /**
   * Capture ID for when establishing a conference session
   */
  get id() {
    if (!this._devices?.video) throw new Error('No devices acquired')
    return this._devices?.video?.id
  }

  get stream() {
    const stream = this.outputSubject.value
    if (!stream) throw new Error('stream called before output set')
    return stream
  }

  get ended() {
    if (!this._devices) throw new Error('Invalid request')
    return this._devices.ended
  }

  /**
   * Called to initiate the screen share workflow
   */
  start() {
    return this.deviceService.capture().pipe(
      map(acquired => {
        if (acquired) {
          this._devices = acquired
          this.captureSubject.next(this._devices.stream)

          // If not in secure mode, we can immediately set the output
          // Otherwise a corresponding video canvas will call setOutput
          // once it has been created.
          if (!this.feature.secure) {
            this.setOutput(this._devices.stream)
          }
          return true
        }
        return false
      })
    )
  }

  stop() {
    this._devices?.close()
  }

  close() {
    this.subscriptions.clear()
    this._devices?.close()
  }

  /**
   * Set the final output stream that should be streamed to the conference
   */
  setOutput(stream: MediaStream) {
    this.logger.debug(`Received output stream ${stream.id}`)
    this.outputSubject.next(stream)
    this.errorService.handleObservable(
      this.conference.screenShare(this),
      {
        errorHandler: () => {
          this.close()
        }
      }
    )
  }

  toString() {
    return this._devices?.toString() || '<no devices>'
  }
}
