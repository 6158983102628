import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';

const ICON_ALIASES: {[key: string]: string} = {
  video: 'film',
  audio: 'music-note-beamed',
  image: 'card-image',
  slideshow: 'collection-play',
  play: 'play-fill',
  invalid: 'question-diamond-fill',
  share: 'cast',
  conference: 'person-video',
  'conference-speaker': 'person-video2',
  'conference-gallery': 'grid-3x2-gap-fill',
  screencast: 'cast',
  logout: 'escape',
  exit: 'escape',
  'layout-simple': 'grid-1x2',
  'layout-grid': 'layout-wtf',
  settings: 'gear',
  'media-manager': 'list-ul',
  'camera': 'camera-video-fill',
  'camera-on': 'camera-video-fill',
  'camera-off': 'camera-video-off-fill',
  'microphone': 'mic-fill',
  'microphone-on': 'mic-fill',
  'microphone-off': 'mic-mute-fill',
  add: 'plus-circle',
  remove: 'dash-circle',
  close: 'x-circle',
  x: 'x-large',
  invite: 'person-fill-add',
  medical: 'heart-pulse',
  'add-fake': 'patch-plus',
  'add-mask': 'plus-square-dotted',
  'delete-mask': 'x',
  lock: 'lock-fill',
  unlock: 'unlock-fill',
  'shape-rectangle': 'square',
  'shape-arrow': 'arrow-right',
  'shape-circle': 'circle',
  'enter-fullscreen': 'arrows-fullscreen',
  'exit-fullscreen': 'fullscreen-exit',
  'disable-device': 'ban'
}

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'd-flex-row justify-content-center align-items-center w-auto',
    style: 'line-height: 100%; display: inline-flex !important;'
  }
})
export class IconComponent {
  iconClass: string = `bi-${ICON_ALIASES['invalid']}`

  constructor(
    private readonly changeDetector: ChangeDetectorRef
  ) {
  }

  @Input()
  set type(val: string) {
    const alias = ICON_ALIASES[val] || ICON_ALIASES['invalid']
    this.iconClass = `bi-${alias}`
    this.changeDetector.markForCheck()
  }
}
