<div *ngFor="let $toast of toasts" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header text-bg-{{$toast.type || 'light'}}">
    <strong class="me-auto">{{$toast.title}}</strong>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="toaster.dismiss($toast)"
    ></button>
  </div>
  <div class="toast-body text-bg-light rounded-bottom">
    <ng-container *ngIf="template($toast); else textContent" [ngTemplateOutlet]="template($toast)"></ng-container>
    <ng-template #textContent>{{$toast.content}}</ng-template>
  </div>
</div>
