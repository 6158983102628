import {Component, HostListener} from '@angular/core';
import {ModalService} from "../modal.service";

@Component({
  selector: 'app-modal-host',
  templateUrl: './modal-host.component.html',
  host: {
    style: 'z-index: 999999'
  }
})
export class ModalHostComponent {
  constructor(
    readonly modalService: ModalService
  ) {
  }

  @HostListener('window:keyup.escape')
  onEscape() {
    this.modalService.cancelTop()
  }
}
