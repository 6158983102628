import {Injectable} from '@angular/core';
// @ts-ignore
import * as ActionCable from '@rails/actioncable';
import {Channel, ChannelState} from "./channel";
import {filter, first, map, Observable, of, switchMap, throwError, timeout} from "rxjs";
import {AnyMap} from "../common/types";
import {Environment} from "../config/environment";
import {hostWithProtocol} from "../common/utilities";

export interface SyncSubscription {
  readonly consumer: SyncConsumer

  perform(action: string, body: AnyMap): void
}

interface SyncConsumer {
  subscriptions: {
    create(one: any, two: any): void,
    remove(subscription: SyncSubscription): void
  }
}

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  private readonly deviceId = window.crypto.randomUUID()
  private readonly consumer: SyncConsumer

  constructor() {
    this.consumer = ActionCable.createConsumer([
      hostWithProtocol(Environment.api.host, 'ws'),
      '/cable?',
      `device_id=${this.deviceId}`
    ].join(''))
  }

  subscribe<T extends Channel<any>>(channelHandler: T, channel: String, params: AnyMap = {}) {
    this.consumer.subscriptions.create(
      Object.assign({channel}, params),
      {
        initialized() {
          channelHandler.initialized(this as unknown as SyncSubscription)
        },
        connected() {
          channelHandler.connected()
        },
        disconnected() {
          channelHandler.disconnected()
        },
        rejected() {
          channelHandler.rejected()
        },
        received(data: any) {
          channelHandler.received(data)
        }
      }
    )

    return channelHandler.state.pipe(
      filter(state => state !== ChannelState.Pending),
      timeout({first: 5000}),
      first(),
      map(state => {
        if (state === ChannelState.Connected) {
          console.debug(`Connected to channel ${channel}`)
          return channelHandler
        } else {
          throw new Error(`Failed to connect to ${channel}`)
        }
      })
    )
  }
}
