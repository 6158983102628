import {
  LocalStageStream,
  Stage,
  StageConnectionState,
  StageEvents,
  StageParticipantInfo,
  StageParticipantPublishState,
  StageParticipantSubscribeState,
  StageStrategy,
  StageStream,
  SubscribeType
} from "amazon-ivs-web-broadcast";
import {catchError, defer, finalize, first, from, Observable, retry, tap} from "rxjs";
import {AwsJoinInfo} from "./aws-conference-provider";
import {AwsConferenceParticipant, awsParticipant} from "./aws-conference-participant";
import {ConferenceSession} from "../conference-session";
import {ConferenceService} from "../conference.service";
import {IDevices} from "../../devices/IDevices";

export class AwsConferenceSession extends ConferenceSession implements StageStrategy {
  private static readonly retryTotalSeconds = 5
  private static readonly retryCount = 10
  private static readonly retryDelay = AwsConferenceSession.retryTotalSeconds * 1000 / AwsConferenceSession.retryCount

  private stageName?: string
  private stage?: Stage
  private stageJoined = false
  private stageStreams!: LocalStageStream[]

  constructor(
    conference: ConferenceService,
    devices?: IDevices,
    screenShare: boolean = false
  ) {
    super(conference, devices, screenShare)

    // Initialize here only if streams weren't implicitly initialized when devices added
    if (!this.stageStreams) this.stageStreams = []
  }

  join(joinInfo: AwsJoinInfo): Observable<unknown> {
    this.logger.context.stage = joinInfo.stage
    this.logger.context.token = [
      joinInfo.token.substring(0, 5),
      joinInfo.token.substring(joinInfo.token.length - 5)
    ].join(':')

    this.createStage(joinInfo as AwsJoinInfo)

    return this.joinStage().pipe(
      first(),
      tap(() => this.onStageJoined()),
      catchError(err => {
        this.logger.warn('Error joining stage', err)
        throw err
      }),
      retry({
        count: AwsConferenceSession.retryCount,
        delay: AwsConferenceSession.retryDelay
      }),
      catchError(err => {
        this.logger.error('Failed to join stage', err)
        this.stageCleanup()
        throw err
      }),
      finalize(() => {
        this.logger.debug('joinStage completed')
      })
    )
  }

  protected override onLeave(): Observable<void> {
    return new Observable<void>(sub => {
      this.stageCleanup()
      this.onSessionEnded()
      sub.complete()
    })
  }

  protected override onDeviceChange() {
    if (this.devices) {
      this.stageStreams = this.devices.stream.getTracks().map(t => new LocalStageStream(t))
    } else {
      this.stageStreams = []
    }
    this.refresh()
  }

  shouldPublishParticipant(_participant: StageParticipantInfo): boolean {
    return this.hasDevices
  }

  shouldSubscribeToParticipant(participant: StageParticipantInfo): SubscribeType {
    if (this.screenShare) {
      // Only subscribe in the primary session
      return SubscribeType.NONE
    } else {
      const it = awsParticipant(this, participant)
      if (it.self) {
        this.logger.debug(`Ignoring ${it}`)
        return SubscribeType.NONE
      } else {
        this.logger.debug(`Subscribing to ${it}`)
        return SubscribeType.AUDIO_VIDEO;
      }
    }
  }

  stageStreamsToPublish(): LocalStageStream[] {
    this.logger.debug(`Publishing ${this.stageStreams.length} streams`)
    return this.stageStreams
  }

  /**
   * Return an observable wrapper for the AWS Stage::join method that is retryable
   * @private
   */
  joinStage() {
    return defer(() => {
      // For some reason when leaving the conference, the Angular zone catches and raises
      // an error from AWS IVS. Tell this code run outside the zone so Angular change detection
      // doesn't hook into this.
      // TODO: Seems like Angular shouldn't be holding on to this after it completes?!?
      return this.conference.ngZone.runOutsideAngular(() => {
        const id = window.crypto.randomUUID()
        this.logger.debug('joining stage', id)
        return from(this.stage!.join().then(() => {
          this.logger.debug('join stage success', id)
        }).catch(err => {
          this.logger.warn('join stage error', id, err)
        }))
      })
    })
  }

  /**
   * Create and initialize the AWS Stage
   */
  private createStage(awsInfo: AwsJoinInfo) {
    this.logger.debug('Creating stage')
    this.stageName = awsInfo.stage
    this.stage = awsInfo.test?.newStage
      ? awsInfo.test?.newStage(awsInfo.token, this)
      : new Stage(awsInfo.token, this)

    // Only listen for events on the primary session
    if (!(this.screenShare || awsInfo.test?.listen === false)) {
      this.stage!.on(
        StageEvents.STAGE_CONNECTION_STATE_CHANGED,
        (state) => this.onStageConnectionStateChanged(state)
      )
      this.listen()
    }
  }

  /**
   * Subscribe to stage events
   */
  private listen() {
    this.stage!.on(
      StageEvents.STAGE_PARTICIPANT_JOINED,
      (participantInfo) => this.onParticipantJoined(awsParticipant(this, participantInfo))
    )
    this.stage!.on(
      StageEvents.STAGE_PARTICIPANT_LEFT,
      (participantInfo) => this.onParticipantLeft(awsParticipant(this, participantInfo))
    )
    this.stage!.on(
      StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED,
      (participantInfo, streams) => this.onParticipantStreamsAdded(
        awsParticipant(this, participantInfo),
        streams
      )
    )
    this.stage!.on(
      StageEvents.STAGE_PARTICIPANT_STREAMS_REMOVED,
      (participantInfo, _streams) => this.onParticipantStreamsRemoved(
        awsParticipant(this, participantInfo)
      )
    )
    this.stage!.on(
      StageEvents.STAGE_PARTICIPANT_PUBLISH_STATE_CHANGED,
      (participantInfo, state) => this.onParticipantPublishStateChanged(
        awsParticipant(this, participantInfo),
        state
      )
    )
    this.stage!.on(
      StageEvents.STAGE_PARTICIPANT_SUBSCRIBE_STATE_CHANGED,
      (participantInfo, state) => this.onParticipantSubscribeStateChanged(
        awsParticipant(this, participantInfo),
        state
      )
    )
  }

  private onStageJoined() {
    this.logger.debug('AWS stage joined')
    this.stageJoined = true
  }

  private onStageConnectionStateChanged(state: StageConnectionState) {
    if (state === StageConnectionState.ERRORED) {
      this.logger.warn('Received connection state error')
    }
  }

  private onParticipantJoined(participant: AwsConferenceParticipant) {
    this.logger.debug(`AWS participant joined: ${participant}`, participant)
    if (participant.showLocal) participant.add()
  }

  private onParticipantLeft(participant: AwsConferenceParticipant) {
    this.logger.debug(`AWS participant left: ${participant}`, participant)
    participant.remove()
  }

  /**
   * Update participant with media streams
   */
  private onParticipantStreamsAdded(participant: AwsConferenceParticipant, stageStreams: StageStream[]) {
    this.logger.debug(`AWS participant started streaming: ${participant}`, participant)
    if (participant.showLocal) {
      participant.setStreams(stageStreams)
    } else {
      this.logger.debug(`Ignoring ${participant}`)
    }
  }

  private onParticipantStreamsRemoved(participant: AwsConferenceParticipant) {
    this.logger.debug(`AWS participant stopped streaming: ${participant}`, participant)
    participant.remove()
  }

  private onParticipantPublishStateChanged(
    participant: AwsConferenceParticipant,
    state: StageParticipantPublishState
  ) {
    if (state === StageParticipantPublishState.ERRORED) {
      this.logger.warn('Publish error received. Attempting refresh.', participant)
      this.refresh()
    }
  }

  private onParticipantSubscribeStateChanged(
    participant: AwsConferenceParticipant,
    state: StageParticipantSubscribeState
  ) {
    if (state === StageParticipantSubscribeState.ERRORED) {
      this.logger.warn('Subscribe error received. Attempting refresh.', participant)
      this.refresh()
    }
  }

  private refresh() {
    this.stage?.refreshStrategy()
  }

  private leaveStage() {
    try {
      if (this.stage && this.stageJoined) {
        this.logger.debug('Leaving AWS stage')
        this.stage.leave()
        this.logger.debug('AWS stage left')
      }
    } catch (err) {
      this.logger.error('Error when leaving AWS stage', err)
    }

    this.stageJoined = false
  }

  private stageCleanup() {
    if (this.stage) {
      this.logger.debug('Cleaning up stage')
      this.stage.removeAllListeners()
      this.leaveStage()
      delete this.stage
      delete this.stageName
    }

    if (this.stageStreams.length > 0) {
      this.stageStreams.forEach(s => s.cleanup())
      this.stageStreams.length = 0
    }
  }

}
