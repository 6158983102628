<ng-template #noDevices>
  <input class="form-control" disabled readonly value="No devices found">
</ng-template>

<div class="d-flex-column gap-3 flex-fill align-items-center">
  <div class="position-relative rounded border border-secondary overflow-hidden">
    <video #cameraVideo class="mx-auto" autoplay muted></video>

    <div class="position-absolute bottom-0 start-0 w-100 bg-black bg-opacity-50">
      <div class="bg-success position-absolute start-0 top-0 bottom-0 h-100 bg-opacity-75"
           [style.width.%]="microphoneLevel"
      ></div>
      <div class="position-relative text-center text-light">
        <icon type="microphone"></icon>
      </div>
    </div>
  </div>

  <div class="d-flex-row w-auto gap-3 align-items-center justify-content-center">
    <label for="cameraDevice" class="form-label fs-4">
      <icon type="video"></icon>
    </label>
    <div class="input-group">
      <ng-container *ngIf="cameras | async as $devices; else noDevices">
        <ng-container *ngIf="$devices.length > 0; else noDevices">
          <select id="cameraDevice"
                  name="camera"
                  class="form-control flex-fill"
                  [class.disabled]="disableCamera"
                  [(ngModel)]="selectedCamera"
                  [disabled]="$devices.length === 0"
          >
            <option *ngFor="let $device of $devices" [ngValue]="$device">{{$device.name}}</option>
          </select>
          <button type="button"
                  *ngIf="$devices.length > 0"
                  class="btn"
                  title="Join with video turned off"
                  [class.btn-light]="!disableCamera"
                  [class.btn-danger]="disableCamera"
                  (click)="disableCamera = !disableCamera"
          >
            <icon type="disable-device"></icon>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="d-flex-row w-auto gap-3 align-items-center justify-content-center">
    <label for="microphoneDevice" class="form-label fs-4">
      <icon type="microphone"></icon>
    </label>
    <div class="input-group">
      <ng-container *ngIf="microphones | async as $devices; else noDevices">
        <ng-container *ngIf="$devices.length > 0; else noDevices">
          <select id="microphoneDevice" name="microphone" class="form-control flex-fill"
                  [(ngModel)]="selectedMicrophone">
            <option *ngFor="let $device of $devices" [ngValue]="$device">{{$device.name}}</option>
          </select>
          <button type="button"
                  class="btn"
                  title="Join with microphone muted"
                  [class.btn-light]="!disableMicrophone"
                  [class.btn-danger]="disableMicrophone"
                  (click)="disableMicrophone = !disableMicrophone"
          >
            <icon type="disable-device"></icon>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>

</div>

