import {inject, TemplateRef, ViewContainerRef} from "@angular/core";

export abstract class IfDirectiveBase {
  private readonly templateRef: TemplateRef<any>
  private readonly viewContainer: ViewContainerRef
  private hasView = false

  constructor() {
    this.templateRef = inject(TemplateRef<any>)
    this.viewContainer = inject(ViewContainerRef)
  }

  set enabled(val: boolean) {
    if (val) {
      this.createView()
    } else {
      this.destroyView()
    }
  }

  private createView() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef)
      this.hasView = true
    }
  }

  private destroyView() {
    if (this.hasView) {
      this.viewContainer.clear()
      this.hasView = false
    }
  }
}
