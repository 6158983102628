import {FeatureModel} from "../api/models/feature.model";
import {WindowComponentClass, WindowInfo} from "../windows/window-info";
import {WindowManagerService} from "../windows/window-manager.service";
import {inject, Type} from "@angular/core";
import {RoomService} from "../room/room.service";
import {Subscriptions} from "../common/subscriptions";
import {filter} from "rxjs";

export abstract class Feature {
  abstract readonly type: string
  abstract readonly name: string

  private _state: any

  /**
   * Override with a component class if the feature adds content
   * to the room controls bar
   */
  readonly controlsComponent?: Type<any>

  protected readonly windowManager: WindowManagerService
  protected readonly roomService: RoomService
  protected readonly subscriptions = new Subscriptions()

  constructor() {
    this.windowManager = inject(WindowManagerService)
    this.roomService = inject(RoomService)
  }

  protected get state() {
    return this._state
  }

  /**
   * Override to add logic when a feature is turned on, such as opening windows
   */
  async enable(feature: FeatureModel): Promise<void> {
    this._state = feature.state
    feature.windows?.forEach(window => {
      this.windowManager.add({ feature: this, window })
    })

    this.subscriptions.set('channel', this.roomService.roomChannel, channel => {
      if (channel) {
        this.subscriptions.set(
          'updated',
          channel.featureUpdated.pipe(filter(message => message.feature.type == this.type)),
          message => this.onUpdated(message.feature)
        )
      } else {
        this.subscriptions.clear('updated')
      }
    })
  }

  /**
   * Override to add logic when a feature is turned off
   */
  async disable(): Promise<void> {
    this.subscriptions.clear()
  }

  /**
   * Override to return the window component class that should be used for
   * a given feature window
   * @param name
   */
  abstract componentForWindow(name: string): WindowComponentClass;

  /**
   * Open a feature window
   * @protected
   */
  protected open() {

  }

  /**
   * Call from derived class
   * @param newState New state
   * @param merge Merge with existing state or replace
   * @protected
   */
  protected updateState(newState: any, {merge = true}: {merge?: boolean} = {}) {
    const state = merge ? Object.assign({}, this._state, newState) : newState
    this.roomService.channel?.updateFeature({ type: this.type, state })
  }

  protected window(name: string): WindowInfo | undefined {
    return this.windowManager.find(this, name)
  }

  /**
   * Override to handle state changes
   * @param state
   * @protected
   */
  protected onStateChange(state: any) {
  }

  private onUpdated(data: FeatureModel) {
    this._state = data.state
    this.onStateChange(this._state)
  }
}
