import {Directive, ElementRef, OnDestroy} from '@angular/core';
import {BehaviorSubject, EMPTY, fromEvent, tap} from "rxjs";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {Subscriptions} from "./subscriptions";

@Directive({
  selector: '[appFullScreen]'
})
export class FullScreenDirective implements OnDestroy {
  private readonly enabledSubject = new BehaviorSubject<boolean>(false)
  private readonly subscriptions = new Subscriptions()

  constructor(
    private readonly el: ElementRef<HTMLElement>
  ) {
    this.subscriptions.add(
      fromEvent(el.nativeElement, 'fullscreenchange'),
      () => this.onFullScreenChange()
    )
  }

  ngOnDestroy() {
    this.subscriptions.clear()
  }

  on() {
    if (this.isFullScreen) {
      return EMPTY
    } else {
      return fromPromise(this.el.nativeElement.requestFullscreen())
    }
  }

  off() {
    if (this.isFullScreen) {
      return fromPromise(document.exitFullscreen())
    } else {
      return EMPTY
    }
  }

  toggle() {
    if (this.isFullScreen) {
      this.off()
    } else {
      this.on()
    }
  }

  get enabled() {
    return this.enabledSubject.asObservable()
  }

  private get isFullScreen() {
    return this.enabledSubject.value
  }

  private onFullScreenChange() {
    if (document.fullscreenElement === this.el.nativeElement) {
      // Exit fullscreen if the Escape key is pressed
      this.subscriptions.set(
        'exit',
        fromEvent<KeyboardEvent>(document, 'keyup'),
        (event: KeyboardEvent) => {
          if (event.key === 'Escape' && !(event.altKey || event.ctrlKey || event.shiftKey || event.metaKey)) {
            this.off()
          }
        }
      )
      this.enabledSubject.next(true)
    } else {
      this.subscriptions.remove('exit')
      this.enabledSubject.next(false)
    }
  }
}
