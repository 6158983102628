import {Injectable} from '@angular/core';
import {SyncService} from "../api/sync.service";
import {RoomChannel} from "./room.channel";
import {RoomDetailsModel, RoomList, RoomModel} from "../api/models/room.model";
import {ApiService} from "../api/api.service";
import {BehaviorSubject, filter, first, from, lastValueFrom, Observable, switchMap} from "rxjs";
import {ChannelState} from "../api/channel";
import {Router} from "@angular/router";
import {Subscriptions} from "../common/subscriptions";
import {FeatureManagerService} from "../features/feature-manager.service";
import {RoomType} from "./room-type";
import {Logging} from "../common/logging";

const CONTROLLER = 'rooms'

export interface CreateRoomBody {
  name: string
  type: RoomType
  start_time?: Date
  end_time?: Date
}

interface InviteUserBody {
  name: string
  start_time?: Date
  end_time?: Date
}

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  readonly current = new BehaviorSubject<RoomModel | undefined>(undefined)
  private readonly _roomChannel = new BehaviorSubject<RoomChannel | undefined>(undefined)
  readonly roomChannel = this._roomChannel.asObservable()

  private readonly subscriptions = new Subscriptions()
  private readonly logger = Logging.for('room', 'room-service')

  constructor(
    private readonly api: ApiService,
    private readonly sync: SyncService,
    private readonly router: Router,
    private readonly featureManager: FeatureManagerService
  ) {
    this.subscriptions.add(
      this.roomChannel,
      newChannel => this.onChannelChange(newChannel)
    )
  }

  get room() {
    return this.current.value
  }

  get channel() {
    return this._roomChannel.value
  }

  list(): Observable<RoomList> {
    return this.api.list<RoomModel>(CONTROLLER)
  }

  create(body: CreateRoomBody): Observable<RoomModel> {
    return this.api.post<RoomModel, CreateRoomBody>(CONTROLLER, {body})
  }

  invite(body: InviteUserBody): Observable<RoomModel> {
    return this.api.post<RoomModel, InviteUserBody>(CONTROLLER, {body, path: `${this.room!.id}/invite`});
  }

  join(roomId: string | RoomModel): Observable<RoomDetailsModel> {
    if (typeof roomId !== 'string') roomId = roomId.id

    return this.api.get<RoomDetailsModel>(CONTROLLER, {path: `${roomId}/join`}).pipe(
      switchMap(room => from(this.initRoom(room)))
    )
  }

  leave(disconnect = true) {
    return from(this.featureManager.stop().then(() => {
      delete this.logger.context.room
      this.current.next(undefined)
      if (disconnect) this.disconnectChannel()
      this.goHome()
    }))
  }

  private goHome() {
    this.router.navigate(['/home']).finally()
  }

  private async initRoom(room: RoomDetailsModel): Promise<RoomDetailsModel> {
    this.current.next(room)
    try {
      await this.featureManager.start(room.features!)
      await this.initChannel(room.id)
    } catch(err) {
      this.disconnectChannel()
      this.current.next(undefined)
      throw err
    }
    this.logger.context.room = { id: room.id, name: room.name }
    this.logger.debug('Room initialized')
    return room
  }

  private onChannelChange(newChannel: RoomChannel | undefined) {
    this.subscriptions.clear()
    if (newChannel) {
      this.subscriptions.set(
        'channel:disconnected',
        newChannel.state.pipe(
          filter(state => state !== ChannelState.Connected),
          first()
        ),
        () => this.onChannelDisconnected()
      )
    } else {
      this.subscriptions.clear('channel:')
    }
  }

  /**
   * Connect to room socket and set up event handlers
   */
  private async initChannel(id: string): Promise<RoomChannel> {
    const channel = await lastValueFrom(this.sync.subscribe(new RoomChannel(), 'RoomChannel', {room_id: id}))
    this._roomChannel.next(channel)
    return channel
  }

  private disconnectChannel() {
    if (this.channel) {
      this.channel.disconnect()
      this._roomChannel.next(undefined)
    }
  }

  private onChannelDisconnected() {
    this._roomChannel.next(undefined)
    this.leave(false).subscribe()
  }



}
