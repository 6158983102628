import {EventEmitter} from "@angular/core";
import {concat, EMPTY, last, Observable, Subscription} from "rxjs";
import {ConferenceService} from "./conference.service";
import {IDevices} from "../devices/IDevices";
import {Logging} from "../common/logging";

/**
 * # Conference Session Handler
 *
 * This is designed to work in 2 modes:
 *
 * ### Conference
 * Established immediately so client can receive info about participants
 * and screen shares and display associated media.
 * Session devices are updated when user enables or disables their own camera/microphone
 *
 * ### Screen Share
 * Established as needed for each window/screen that the local user shares
 */
export abstract class ConferenceSession {
  readonly id = crypto.randomUUID()
  protected endedEvent = new EventEmitter(true)
  readonly ended = this.endedEvent.asObservable()

  private _devices?: IDevices
  private sessionEnded = false
  private devicesEnded = false
  private devicesEndedSubscription?: Subscription
  // Cached device ID so that we stop the share even if device info is cleaned up first
  private screenShareDeviceId?: string
  protected logger = Logging.for('conference', () => `conference:${this.id}`)

  constructor(
    readonly conference: ConferenceService,
    devices?: IDevices,
    readonly screenShare: boolean = false
  ) {
    if (screenShare && !devices) throw new Error('devices required for screen share session')
    if (devices) this.devices = devices

    if (screenShare) this.logger.context.screen = true
    this.logger.debug('Created session')
  }

  get devices() {
    return this._devices
  }

  set devices(devices) {
    const hadDevices = !!this._devices

    if (devices) {
      this.logger.debug(`Setting devices`, devices)
    }

    if (hadDevices) {
      // Only allow once from constructor if screen share
      if (this.screenShare) throw new Error('Cannot update screen share')
      this.cleanupDevices()
    }

    this._devices = devices
    if (devices && this.screenShare) {
      this.screenShareDeviceId = devices.id
      this.devicesEndedSubscription = devices.ended.subscribe(() => this.onDevicesEnded())
    }

    if (hadDevices || devices) {
      this.onDeviceChange()
    }
  }

  get hasDevices() {
    return !!this.devices
  }

  leave(): Observable<void> {
    // Call derived class' onLeave handler to leave the conference locally
    // then notify the backend that we've left so it can do any cleanup there
    const op = concat(
      this.onLeave(),
      this.screenShare
      ? (this.screenShareDeviceId ? this.conference.api.stopShare(this.screenShareDeviceId) : EMPTY)
      : this.conference.api.leave()
    ).pipe(last())

    return this.conference.error.handleObservable(op)
  }

  /**
   * Override in derived class for additional cleanup
   */
  protected onLeave(): Observable<void> { return EMPTY }

  /**
   * Override in derived class for additional handling
   */
  protected onDeviceChange(): void {}

  /**
   * Call from derived class to signal that the session has been ended
   */
  protected onSessionEnded() {
    this.sessionEnded = true
    this.endedEvent.emit()
    this.cleanup()
  }

  private onDevicesEnded() {
    this.devicesEnded = true
    this.cleanupDevices(() => {
      if (!this.sessionEnded) this.leave()
    })
  }

  private cleanup() {
    this.endedEvent.complete()
    this.cleanupDevices()
  }

  /**
   * Cleanup device info
   * @param beforeDelete Optional callback to run before fully deleting device reference
   */
  private cleanupDevices(beforeDelete?: () => void) {
    this.devicesEndedSubscription?.unsubscribe()
    delete this.devicesEndedSubscription

    if (this._devices && !this.devicesEnded) {
      this.devicesEnded = true
      this._devices.close()
    }

    if (beforeDelete) beforeDelete()
    delete this._devices
  }
}
