import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ContentChild, ContentChildren,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren
} from '@angular/core';
import {Subscriptions} from "../subscriptions";
import {ObservableList} from "../observable-list";
import {AutoGridZoomedDirective} from "./auto-grid-zoomed.directive";
import {AutoGridItemDirective} from "./auto-grid-item.directive";
import {ComponentWithSubscriptions} from "../component-with-subscriptions";

@Component({
  selector: 'auto-grid',
  template: '<ng-content></ng-content>',
  host: {
    class: 'd-grid p-2 gap-2 overflow-hidden',
    style: 'grid-auto-rows: 1fr'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoGridComponent extends ComponentWithSubscriptions implements AfterContentInit {
  @ContentChildren(AutoGridZoomedDirective) zoomed!: QueryList<AutoGridZoomedDirective>
  @ContentChildren(AutoGridItemDirective) gridItems?: QueryList<AutoGridItemDirective>

  @HostBinding('style.grid-template-columns')
  gridTemplateColumns: string = ''

  constructor(
    private readonly changeDetector: ChangeDetectorRef
  ) {
    super()
    this.setGridTemplate()
  }

  ngAfterContentInit() {
    this.subscriptions.add(this.zoomed.changes, () => this.onZoomedChange())
    this.subscriptions.add(this.gridItems!.changes, () => this.onGridItemsChange())
    this.setGridTemplate()
  }

  private onZoomedChange() {

  }

  private onGridItemsChange() {
    this.setGridTemplate()
    this.changeDetector.markForCheck()
  }

  private setGridTemplate() {
    const count = Math.max(1, this.gridItems?.length || 0)
    this.gridTemplateColumns = `repeat(${Math.ceil(Math.sqrt(count))}, 1fr)`
  }
}
