import {
  BehaviorSubject,
  catchError,
  EMPTY,
  filter,
  finalize,
  first,
  forkJoin,
  Observable,
  of, share,
  switchMap,
  tap
} from "rxjs";
import {DeviceService} from "../devices/device.service";
import {ErrorService} from "../common/error.service";
import {ConferenceParticipant} from "./conference-participant";
import {ConferenceParticipantList} from "./conference-participant-list";
import {AcquiredDevices} from "../devices/acquired-devices";
import {ConferenceProvider, ConferenceProviderClass} from "./conference-provider";
import {AwsConferenceProvider} from "./aws/aws-conference-provider";
import {ConferenceSession} from "./conference-session";
import {ConferenceApiService} from "./conference-api.service";
import {Injectable, NgZone} from "@angular/core";
import {AuthService} from "../api/auth.service";
import {RoomService} from "../room/room.service";
import {ConferenceScreenShare} from "./conference-screen-share";
import {Subscriptions} from "../common/subscriptions";
import {RoomChannel} from "../room/room.channel";
import {IDevices} from "../devices/IDevices";
import {Logging} from "../common/logging";

export interface ConferenceJoinInfo {
  provider?: 'default'
}

const CONFERENCE_PROVIDERS: { [name: string]: ConferenceProviderClass<any> } = {
  default: AwsConferenceProvider,
  aws: AwsConferenceProvider
}

@Injectable({providedIn: 'root'})
export class ConferenceService {
  private readonly testMode = false
  private readonly _joined = new BehaviorSubject<boolean>(false)
  readonly joined = this._joined.asObservable()
  readonly participants = new ConferenceParticipantList()
  readonly screens = new ConferenceParticipantList<ConferenceScreenShare>()
  private readonly provider: ConferenceProvider<any>
  private conferenceSession?: ConferenceSession
  private readonly screenShareSessions = new Set<ConferenceSession>()
  private readonly subscriptions = new Subscriptions()
  private readonly logger = Logging.for('conference', 'conference-service')

  constructor(
    readonly api: ConferenceApiService,
    protected readonly devices: DeviceService,
    readonly error: ErrorService,
    private readonly auth: AuthService,
    private readonly room: RoomService,
    readonly ngZone: NgZone
  ) {
    this.provider = new CONFERENCE_PROVIDERS['default'](this)
    this.subscriptions.add(
      this.room.roomChannel,
      channel => this.onChannelChanged(channel)
    )
  }

  get self(): string | undefined {
    return this.auth.user?.id
  }

  get isJoined() {
    return this._joined.value
  }

  /**
   * Join or start a conference
   */
  join(withMedia = true): Observable<unknown> {
    return this.error.handleObservable(this.joinObserver(withMedia))
  }

  /**
   * Leave the conference
   */
  leave(): Observable<any> {
    const sessions = Array.from(this.screenShareSessions.values())
    if (this.conferenceSession) sessions.push(this.conferenceSession)

    if (sessions.length > 0) {
      // Note that each ConferenceSession.leave handles its own errors, so we
      // just need to return the observable and ensure things are cleaned up
      // when all sessions have been torn down.
      return forkJoin(sessions.map(session => session.leave())).pipe(
        finalize(() => {
            this.screenShareSessions.clear()
            delete this.conferenceSession
          })
      )
    } else {
      return EMPTY
    }
  }

  screenShare(devices: IDevices): Observable<ConferenceSession> {
    return this.api.startShare(devices.id).pipe(
      switchMap(joinInfo => this.startScreenShare(joinInfo, devices))
    )
  }

  addFakeParticipant() {
    const i = this.participants.length + 1
    this.participants.add(new ConferenceParticipant(
      `participant${i}`,
      `Participant ${i}${i}${i}`
    ))
  }

  private onChannelChanged(channel: RoomChannel | undefined) {
    this.subscriptions.remove('channel:')
    if (channel) {
      this.subscriptions.set(
        'channel:conference-started',
        channel.conferenceStarted,
        () => this.join(false)
      )
    }
  }

  private joinObserver(withMedia: boolean = false) {
    let observable: Observable<any>
    let newDevices: AcquiredDevices

    // No-op if we've already joined
    if (this.isJoined && (!withMedia || this.conferenceSession?.devices)) {
      return EMPTY
    }

    // Ensure cleanup
    if (!this.isJoined) {
      this.participants.clear()
      this.screens.clear()
    }

    if (withMedia) {
      observable = this.devices.acquirePrompt().pipe(
        filter(devices => !!devices),
        tap(devices => {
          this.logger.debug('Received devices', devices)
          newDevices = devices!
        })
      )
    } else {
      observable = of(undefined)
    }

    if (withMedia && this.isJoined) {
      observable = observable.pipe(
        tap(devices => {
          if (devices) this.conferenceSession!.devices = devices
        })
      )
    } else if (!this.isJoined) {
      observable = observable.pipe(
        switchMap<AcquiredDevices | undefined, Observable<ConferenceSession | undefined>>(devices => {
          return this.testMode
            ? this.joinTestConference()
            : this.joinConference(devices)
        }),
        tap(session => {
          this.setJoined(true)
          session?.ended.subscribe(() => this.onConferenceEnded())
        }),
        catchError(err => {
          newDevices?.close()
          this.cleanup()
          throw err
        })
      )
    }

    return observable
  }

  private joinTestConference() {
    this.participants.add(
      new ConferenceParticipant('Participant1', 'Some Guy'),
      new ConferenceParticipant('Participant2', 'Some Girl')
    )
    return of(undefined)
  }

  private joinConference(devices?: AcquiredDevices): Observable<ConferenceSession> {
    this.logger.debug('Requesting conference join info')
    return this.api.join().pipe(
      switchMap(joinInfo => {
        return this.provider.join(joinInfo, {devices})
      }),
      tap(session => {
        this.conferenceSession = session
      })
    )
  }

  private onConferenceEnded() {
    this.setJoined(false)
    delete this.conferenceSession
    this.cleanup()
  }

  private startScreenShare(joinInfo: ConferenceJoinInfo, devices: IDevices) {
    return this.provider.join(joinInfo, {devices, screenShare: true}).pipe(
      tap(session => {
        this.logger.debug(`Screen share session ${session.id} started`)
        // Add to screen share list
        this.screenShareSessions.add(session)
        // Handle when screen share is ended
        this.subscriptions.set(
          `${session.id}:ended`,
          session.ended,
          () => {
            this.logger.debug(`Screen share session ${session.id} ended`)
            this.screenShareSessions.delete(session)
            this.subscriptions.clear(session.id)
          }
        )
      })
    )
  }

  private setJoined(value: boolean) {
    if (value !== this._joined.value) this._joined.next(value)
  }

  private cleanup() {
    this.participants.clear()
  }
}
