import {inject, Injectable, Injector, Type} from '@angular/core';
import {Feature} from "./feature";
import {BehaviorSubject, catchError, EMPTY, last, map, Observable, of, switchMap, tap, throwError} from "rxjs";
import {ConferenceFeature} from "./conference.feature";
import {FeatureModel} from "../api/models/feature.model";

const FEATURE_CLASSES: { [type: string]: Type<Feature> } = {
  conference: ConferenceFeature
}

type FeatureSpec = string | FeatureModel

@Injectable({
  providedIn: 'root'
})
export class FeatureManagerService {
  private readonly featuresSubject = new BehaviorSubject<Feature[]>([])
  private readonly enabledFeatures = new Map<string, Feature>()

  readonly features = this.featuresSubject.asObservable()

  constructor(
    private readonly injector: Injector
  ) {
  }

  async start(features: FeatureSpec[]): Promise<void> {
    await this.stop()
    await this.enableFeatures(features)
  }

  async stop(): Promise<void> {
    const features = Array.from(this.enabledFeatures.values())
    this.enabledFeatures.clear()

    for (const featureInstance of features) {
      try {
        await featureInstance.disable()
      } catch (err) {
        console.error('Failed to disable {} feature', featureInstance.name, err)
      }
    }

    this.featuresSubject.next([])
  }

  get(name: string): Feature {
    const it = this.enabledFeatures.get(name)
    if (!it) throw new Error(`Attempt to retrieve unknown feature: ${name}`)
    return it
  }

  private async enableFeatures(features: FeatureSpec[]): Promise<void> {
    try {
      const list: Feature[] = []
      for (const spec of features) {
        const featureModel: FeatureModel = typeof spec === 'string' ? {type: spec} : spec
        const featureInstance = await this.createFeature(featureModel)
        this.enabledFeatures.set(featureInstance.type, featureInstance)
        list.push(featureInstance)
      }
      this.featuresSubject.next(list)
    } catch(err) {
      await this.cleanupError(err as Error)
      throw err
    }
  }

  private async createFeature(featureModel: FeatureModel) {
    const featureClass = FEATURE_CLASSES[featureModel.type]
    if (!featureClass) throw new Error(`Unknown feature: ${featureModel.type}`)

    const featureInstance = this.injector.get(featureClass)
    await featureInstance.enable(featureModel)

    return featureInstance
  }

  private async cleanupError(err: Error): Promise<void> {
    await this.stop()
  }
}
