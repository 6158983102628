<ng-container *ngFor="let $feature of featureManager.features | async; first as $first">
  <div *ngIf="!$first" class="row-divider"></div>

  <ng-container
    *ngIf="$feature.controlsComponent"
    [ngComponentOutlet]="$feature.controlsComponent"
  ></ng-container>
</ng-container>

<div class="flex-fill"></div>

<app-button
  icon="invite"
  title="Invite People"
  (click)="invite()"
></app-button>

<app-button
  [transparent]="false"
  [small]="true"
  color="danger"
  text="Leave"
  (click)="leave()"
></app-button>
