import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import {asyncScheduler, interval, retry, Subscription} from "rxjs";
import {AnnotationsComponent} from "../../annotations/annotations/annotations.component";
import {Subscriptions} from "../subscriptions";
import {DrawingTargetDirective} from "../../annotations/drawing-target.directive";

@Component({
  selector: 'app-video-canvas',
  templateUrl: './video-canvas.component.html',
  styleUrls: ['./video-canvas.component.scss'],
  host: {
    class: 'position-relative'
  }
})
export class VideoCanvasComponent implements AfterViewInit, OnDestroy {
  private readonly FRAME_RATE = 10

  private _videoSource?: MediaStream | string | null
  private playing = false
  private videoContext!: CanvasRenderingContext2D
  private drawFrameTimer?: Subscription
  private masks: DOMRect[] = []
  private readonly subscriptions = new Subscriptions()
  private maskCanvas?: OffscreenCanvas

  @Input() loop = false
  @Output() readonly ended = new EventEmitter()
  @Output() readonly output = new EventEmitter<MediaStream>(true)

  @ViewChild('videoElement') protected videoElement!: ElementRef<HTMLVideoElement>
  @ViewChild(DrawingTargetDirective) protected videoCanvas!: DrawingTargetDirective

  constructor(
    @Optional() annotations: AnnotationsComponent
  ) {
    if (annotations) {
      this.subscriptions.add(
        annotations.masks,
        masks => {
          this.masks = masks
          asyncScheduler.schedule(() => this.updateMasks())
        }
      )
    }
  }

  ngAfterViewInit() {
    this.videoContext = this.canvas.getContext('2d', {willReadFrequently: true})!
    this.updateMasks()
    if (this._videoSource) this.initVideo()
  }

  ngOnDestroy() {
    this.destroyVideo()
    delete this.maskCanvas
    this.subscriptions.clear()
  }

  @Input()
  get videoSource() {
    return this._videoSource
  }

  set videoSource(val) {
    this._videoSource = val
    if (val) {
      this.initVideo()
    } else {
      this.destroyVideo()
    }
  }

  onPlay() {
    this.playing = true
    this.updateCanvasSize()
    this.drawFrameTimer = interval(1000 / this.FRAME_RATE)
      .pipe(retry({delay: 5000}))
      .subscribe(() => this.drawFrame())
  }

  onEnded() {
    this.destroyVideo()
    this.ended.emit()
  }

  private get video() {
    return this.videoElement.nativeElement
  }

  private get canvas() {
    // This will always be our canvas element
    return this.videoCanvas.canvas!
  }

  private drawFrame() {
    if (!this.playing) return

    requestAnimationFrame(() => {
      this.updateCanvasSize()
      this.videoContext.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight)

      // Apply masks, if any
      if (this.maskCanvas) {
        this.videoContext.drawImage(this.maskCanvas, 0, 0)
      }
    })
  }

  private updateCanvasSize() {
    this.videoCanvas.resize(this.video.videoWidth, this.video.videoHeight)
  }

  /**
   * Setup playback on the hidden video element
   */
  private initVideo() {
    if (this.videoElement) {
      if (typeof this._videoSource === 'string') {
        this.video.src = this._videoSource
      } else {
        this.video.srcObject = this._videoSource || null
      }

      // Notify listeners of the canvas video stream which will contain
      // the masked video
      this.output.emit(this.canvas.captureStream())
    }
  }

  private destroyVideo() {
    this.playing = false
    this.drawFrameTimer?.unsubscribe()
    delete this.drawFrameTimer
    this.videoElement?.nativeElement?.pause()
  }

  /**
   * Create/update a pre-rendered mask layer for quick frame rendering
   */
  private updateMasks() {
    if (this.videoCanvas) {
      if (this.masks.length > 0) {
        const hadMasks = !!this.maskCanvas

        if (!this.maskCanvas) {
          this.maskCanvas = new OffscreenCanvas(this.canvas.width, this.canvas.height)
        }

        const maskContext = this.maskCanvas.getContext('2d')!

        if (hadMasks) {
          maskContext.clearRect(0, 0, this.maskCanvas.width, this.maskCanvas.height)
        }

        maskContext.fillStyle = 'black'
        this.masks.forEach(mask => {
          maskContext.fillRect(mask.x, mask.y, mask.width, mask.height)
        })
      } else {
        delete this.maskCanvas
      }
    }
  }
}
