import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailName'
})
export class EmailNamePipe implements PipeTransform {
  static readonly PATTERN = /@.+/

  transform(value: string): string {
    return value?.replace(EmailNamePipe.PATTERN, '')
  }
}
