import {Component, Input} from '@angular/core';
import {ModalComponent} from "../modal.component";
import {ModalConfig} from "../modal";
import {ColorStyle} from "../../types";

@Component({
  template: '<div class="message">{{message}}</div>',
  styles: [
    `
      .message {
        min-width: 60ch;
        max-width: 70ch;
      }
    `
  ]
})
export class ErrorModalComponent extends ModalComponent {
  static modalConfig: ModalConfig = {
    title: 'Error',
    style: ColorStyle.Danger
  }

  @Input() message: string = ''
}
