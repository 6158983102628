import {WindowComponent} from "./window/window.component";
import {inject, TemplateRef} from "@angular/core";

export abstract class WindowComponentImpl {
  protected window: WindowComponent

  readonly title?: string
  readonly icon?: string
  readonly controls?: TemplateRef<any>
  readonly canClose = false

  constructor() {
    this.window = inject(WindowComponent)
    this.window.setComponent(this)
  }
}
