import {EntityList, EntityMap, EntityModel} from "./entity.model";

export enum WindowPosition {
  Hidden = 'hidden',
  Normal = 'normal',
  Minimized = 'minimized',
  Maximized = 'maximized',
  DockLeft = 'dock_left',
  DockRight = 'dock_right',
  DockTop = 'dock_top',
  DockBottom = 'dock_bottom'
}

export interface WindowModel extends EntityModel {
  id: string
  name: string
  x: number
  y: number
  width: number
  height: number
  position: WindowPosition
  order: number
  state?: any
}

export type WindowList = EntityList<WindowModel>
export type WindowMap = EntityMap<WindowModel>

// Update existing window
export type UpdateWindowModel = Pick<WindowModel, 'id'> & Partial<Omit<WindowModel, 'id'>>
// Update window without ID
export type UpdateThisWindowModel = Partial<Omit<WindowModel, 'id'>>
// Request new window
export type CreateWindowModel = Omit<WindowModel, 'id'>
