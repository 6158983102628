import {Component, OnInit} from '@angular/core';
import {AcquireOptions, DeviceService} from "../../devices/device.service";
import {timer} from "rxjs";

@Component({
  templateUrl: './test-devices.component.html',
  styleUrls: ['./test-devices.component.scss'],
  host: {
    class: 'p-5 d-block'
  }
})
export class TestDevicesComponent implements OnInit {
  protected selectedDevices?: AcquireOptions

  constructor(
    protected readonly devices: DeviceService
  ) {
  }

  ngOnInit() {
    timer(1000).subscribe(() => this.prompt())
  }

  prompt() {
    this.devices.prompt().subscribe(result => {
      this.selectedDevices = result
    })
  }
}
