import {EventEmitter} from "@angular/core";

type ItemFilter<T> = ((t: T) => unknown)
type ItemOrFilter<T> = T | ItemFilter<T>

export class ObservableList<T> implements Iterable<T> {
  private readonly items: Array<T> = []
  private readonly changedEvent = new EventEmitter(true)
  public readonly changed = this.changedEvent.asObservable()

  constructor() {
  }

  [Symbol.iterator](): Iterator<T> {
    return this.items[Symbol.iterator]()
  }

  get length() {
    return this.items.length
  }

  get last() {
    return this.items.length > 0 ? this.items[this.items.length - 1] : undefined
  }

  add(...items: T[]) {
    this.items.push(...items)
    this.emitChanged()
  }

  remove(item: ItemOrFilter<T>) {
    this.removeIndex(this.findIndex(item))
  }

  removeIndex(index: number) {
    if (index >= 0) {
      this.items.splice(index, 1)
      this.emitChanged()
    }
  }

  clear() {
    this.items.length = 0
    this.emitChanged()
  }

  exists(item: ItemOrFilter<T>): boolean {
    return this.findIndex(item) >= 0
  }

  find(filter: ItemFilter<T>): T | undefined {
    return this.items.find(filter)
  }

  findIndex(item: ItemOrFilter<T>): number {
    if (typeof item === 'function') {
      return this.items.findIndex(item as ItemFilter<T>)
    } else {
      return this.items.findIndex(val => val === item)
    }
  }

  map<U>(mapper: (value: T, index: number) => U): U[] {
    return this.items.map(mapper)
  }

  forEach(callback: (value: T, index: number) => void): void {
    return this.items.forEach(callback)
  }

  private emitChanged() {
    this.changedEvent.emit()
  }
}
