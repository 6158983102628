import {BehaviorSubject} from "rxjs";

export interface ConferenceParticipantOptions {
  self?: boolean
}

export class ConferenceParticipant {
  private readonly _stream = new BehaviorSubject<MediaStream | undefined>(undefined)
  readonly self: boolean
  private _hasVideo = false
  private _hasMicrophone = false

  constructor(
    readonly id: string,
    readonly name: string,
    options: ConferenceParticipantOptions = {}
  ) {
    this.self = !!options.self
  }

  get stream() {
    return this._stream.asObservable()
  }

  get hasVideo() {
    return this._hasVideo
  }

  get hasMicrophone() {
    return this._hasMicrophone
  }

  setTracks(tracks: MediaStreamTrack[]) {
    if (tracks.length > 0) {
      const mediaStream = new MediaStream()
      this._hasMicrophone = false
      this._hasVideo = false

      tracks.forEach(t => {
        switch(t.kind) {
          case 'video': this._hasVideo = true; break
          case 'audio': this._hasMicrophone = true; break;
        }
        mediaStream.addTrack(t)
      })

      this._stream.next(mediaStream)
    } else {
      this.resetMedia()
    }
  }

  resetMedia() {
    if (this._stream.value !== null) this._stream.next(undefined)
  }
}
