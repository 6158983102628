<div class="d-flex-row gap-2">
  <button type="button" class="btn btn-dark" (click)="largeWindow = !largeWindow">
    {{ largeWindow ? 'Large' : 'Small' }} Window
  </button>

  <button
    type="button"
    class="btn"
    [class.btn-success]="annotations.showMasks"
    [class.btn-danger]="!annotations.showMasks"
    (click)="annotations.showMasks = !annotations.showMasks"
  >
    Masks {{ annotations.showMasks ? 'Enabled' : 'Disabled' }}
  </button>
</div>

<div
  class="bg-black overflow-hidden border border-1 rounded"
  [style.width.px]="(largeWindow ? largeSize : smallSize) + 200"
  [style.height.px]="largeWindow ? largeSize : smallSize"
>
  <app-annotations #annotations>
    <app-video-canvas
      videoSource="assets/sample.mp4"
      [loop]="true"
      (output)="canvasStream = $event"
    ></app-video-canvas>
  </app-annotations>
</div>

<video
  *ngIf="canvasStream"
  [srcObject]="canvasStream"
  autoplay
  style="max-width: 300px; max-height: 400px;"
  class="object-fit-contain bg-black border border-1 rounded"
></video>
