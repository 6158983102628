import { Injectable } from '@angular/core';
import {RoomService} from "../room/room.service";
import {ApiService} from "../api/api.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConferenceApiService {
  constructor(
    protected readonly roomService: RoomService,
    protected readonly api: ApiService
  ) { }

  join(): Observable<any> {
    return this.api.get(this.controller, {path: 'join'})
  }

  leave(): Observable<any> {
    return this.api.get(this.controller, {path: 'leave'})
  }

  startShare(deviceId: string): Observable<any> {
    return this.api.post(this.controller, {path: `share/${deviceId}`})
  }

  stopShare(deviceId: string): Observable<any> {
    return this.api.delete(this.controller, {path: `share/${deviceId}`})
  }

  private get controller() {
    return `rooms/${this.roomService.room!.id}/conference`
  }
}
