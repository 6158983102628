import {Component, TemplateRef} from '@angular/core';
import {ToasterService} from "./toaster.service";

export interface Toast {
  type?: 'success' | 'info' | 'warning' | 'danger'
  title: string
  content: string | TemplateRef<any>
  timeout?: 'short' | 'long'
}

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  host: {
    class: 'd-flex-column gap-3 position-fixed p-3 end-0 bottom-0',
    style: 'z-index: 999999; height: fit-content'
  }
})
export class ToasterComponent {
  readonly toasts: Toast[] = []

  constructor(
    readonly toaster: ToasterService
  ) {
    toaster.toasterComponent = this
  }

  template(toast: Toast): TemplateRef<any> | null {
    return typeof toast.content === 'string' ? null : toast.content
  }
}
