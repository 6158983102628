<ng-template #windowList let-$list="list" let-$showEmpty="showEmpty">
  <ng-container *ngIf="$list.length > 0; else empty">
    <app-window
      *ngFor="let $window of $list"
      [window]="$window"
    ></app-window>
  </ng-container>

  <ng-template #empty>
    <div *ngIf="$showEmpty" class="position-absolute start-50 top-50 translate-middle">
      <span class="fs-1">{{roomName}}</span>
    </div>
  </ng-template>
</ng-template>


<div class="dock-top d-flex-column h-auto mw-100">
  <ng-container [ngTemplateOutlet]="windowList" [ngTemplateOutletContext]="topWindows | async"></ng-container>
</div>

<div class="dock-left d-flex-row w-auto">
  <ng-container [ngTemplateOutlet]="windowList" [ngTemplateOutletContext]="leftWindows | async"></ng-container>
</div>

<div class="main d-grid p-1 gap-1 fill" [style.grid-template]="layoutGridTemplate">
  <ng-container [ngTemplateOutlet]="windowList" [ngTemplateOutletContext]="normalWindows | async"></ng-container>
</div>

<div class="dock-right d-flex-row w-auto mh-100">
  <ng-container [ngTemplateOutlet]="windowList" [ngTemplateOutletContext]="rightWindows | async"></ng-container>
</div>

<div class="dock-bottom d-flex-column h-auto mw-100">
  <ng-container [ngTemplateOutlet]="windowList" [ngTemplateOutletContext]="bottomWindows | async"></ng-container>
</div>
