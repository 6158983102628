<ng-container *ngIf="room | async as $room">
  <app-window-layout class="flex-fill"></app-window-layout>
  <app-room-host-controls></app-room-host-controls>
</ng-container>

<ng-template #joinConference>
  <div>Conference in progress... Join now?</div>
  <div class="mt-2 pt-2 border-top d-flex-row gap-2">
    <app-button
      color="success"
      data-bs-dismiss="toast"
      [transparent]="false"
      [small]="true"
      text="Join"
      icon="conference"
      (click)="conference.join()"
    ></app-button>
    <button type="button" class="btn text-danger btn-sm" data-bs-dismiss="toast">No Thanks</button>
  </div>
</ng-template>
