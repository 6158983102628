import {formatTime} from "./utilities";

export enum LogLevel {
  DEBUG,
  INFO,
  WARN,
  ERROR
}

export interface LogConfig {
  level: LogLevel
}

export type LoggerName = string | (() => string)

export class Logger {
  readonly context: any = {}

  constructor(
    private readonly config: LogConfig,
    readonly name?: LoggerName
  ) {
  }

  get level() {
    return this.config.level
  }

  isLevel(level: LogLevel) {
    return (level >= this.level)
  }

  log(level: LogLevel, message: string, ...data: any[]) {
    if (this.isLevel(level)) {
      if (this.hasContext) data.push(this.contextString())

      // @ts-ignore
      console[LogLevel[level].toLowerCase()](
        this.formatMessage(message),
        ...data.map(it => this.inspect(it))
      )
    }
  }

  debug(message: string, ...data: any[]) {
    this.log(LogLevel.DEBUG, message, ...data)
  }

  info(message: string, ...data: any[]) {
    this.log(LogLevel.INFO, message, ...data)
  }

  warn(message: string, ...data: any[]) {
    this.log(LogLevel.WARN, message, ...data)
  }

  error(message: string, ...data: any[]) {
    this.log(LogLevel.ERROR, message, ...data)
  }

  group(message: string, block: () => void) {
    console.group(this.formatMessage(message))
    try {
      block()
    } finally {
      console.groupEnd()
    }
  }

  private getName() {
    if (typeof this.name === 'string') {
      return this.name
    } else {
      return this.name!()
    }
  }

  private get hasContext() {
    return Object.keys(this.context).length > 0
  }

  private formatMessage(message: string): string {
    const now = new Date()

    const parts = ['[', formatTime(now, {milliseconds: true}), ']']
    if (this.name) {
      parts.push('[', this.getName(), ']')
    }
    parts.push(' ', message)

    return parts.join('')
  }

  private inspect(it: unknown): string {
    if (it === null) {
      return '<null>'
    } else if (it === undefined) {
      return '<undefined>'
    } else if (it.toString === Object.prototype.toString) {
      return JSON.stringify(it)
    } else {
      return it.toString()
    }
  }

  private contextString(): string {
    const tags = Object.keys(this.context).map(
      key => `${key}:${JSON.stringify(this.context[key])}`
    )

    return (tags.length > 0) ? `[${tags.join(', ')}]` : ''
  }
}
