<div class="participants-container w-100 h-100 overflow-hidden">
  <auto-grid *ngIf="conference.joined | async"
             class="participants w-100 h-100"
  >
    <app-conference-participant
      *ngFor="let $participant of conference.participants"
      [participant]="$participant"
    ></app-conference-participant>
  </auto-grid>
</div>
