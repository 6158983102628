import {Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {default as Konva} from 'konva';
import {Subscriptions} from "../common/subscriptions";
import {asyncScheduler, BehaviorSubject, timer} from "rxjs";
import {DrawingTargetDirective} from "./drawing-target.directive"
import {Logging} from "../common/logging";

interface Point {
  x: number,
  y: number
}

interface Rects {
  offset?: Point
  scale: Point
  width: number
  height: number
}

@Component({
  selector: 'app-drawing',
  template: ''
})
export class DrawingComponent implements OnInit, OnDestroy {
  @Input() color = 'red'

  private stage!: Konva.Stage
  private layer!: Konva.Layer
  private selector!: Konva.Transformer
  private _target?: DrawingTargetDirective
  private subscriptions = new Subscriptions()
  private resizeObserver: ResizeObserver
  private _maskMode = false
  private offset: { x: number, y: number } = {x: 0, y: 0}
  private readonly maskList: Konva.Rect[] = []
  private readonly logger = Logging.for('annotations', 'drawing')

  private readonly maskName = '**MASK**'
  private readonly default: any = {
    x: 20,
    y: 20,
    width: 100,
    height: 100,
    fill: 'transparent',
    strokeWidth: 4,
    pointerLength: 20,
    pointerWidth: 20,
    maskColor: '#88000088'
  }

  private masksSubject = new BehaviorSubject<DOMRect[]>([])

  @Output()
  readonly masks = this.masksSubject.asObservable()

  constructor(
    private readonly el: ElementRef<HTMLDivElement>
  ) {
    // Set up a resize observer that triggers a size recalculation
    this.resizeObserver = new ResizeObserver(() => {
      this.subscriptions.set('updateSize', timer(100), () => this.updateSize())
    })
  }

  @Input()
  set maskMode(value: boolean) {
    this._maskMode = value

    this.layer.getChildren(
      node => node.getClassName() !== 'Transformer'
    ).forEach(shape => {
      const isMask = shape.name() === this.maskName
      if (isMask == value) {
        shape.show()
      } else {
        shape.hide()
      }
    })

    this.selector.nodes([])

    if (!value) this.onMasksChanged()
  }

  get maskMode() {
    return this._maskMode
  }

  set target(value: DrawingTargetDirective) {
    this._target = value
    this.resizeObserver.observe(value.element)
    this.subscriptions.set('canvas:resized', value.resized, () => this.updateSize())
    if (this.stage) this.updateSize()
  }

  @HostBinding('style.paddingLeft.px')
  get paddingLeft() {
    return this.offset.x
  }

  @HostBinding('style.paddingTop.px')
  get paddingTop() {
    return this.offset.y
  }

  ngOnInit() {
    const rects = this.calcRects()
    this.setOffset(rects)
    delete rects.offset

    this.stage = new Konva.Stage(Object.assign(
      {container: this.el.nativeElement},
      this.calcRects()
    ))

    this.layer = new Konva.Layer()
    this.stage.add(this.layer)

    this.selector = new Konva.Transformer({keepRatio: false})
    this.layer.add(this.selector)

    this.resizeObserver.observe(this.el.nativeElement)
    this.stage.on('click tap', e => this.onClick(e))
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect()
    this.subscriptions.clear()
    this.stage.destroy()
  }

  addArrow() {
    this.addShape(new Konva.Arrow(this.shapeOptions({
      points: [0, 0, this.defaultValue('width'), 0],
      fill: this.color,
      stroke: this.color,
    }, 'x', 'y', 'pointerLength', 'pointerWidth')))
  }

  addRectangle() {
    this.addShape(new Konva.Rect(this.shapeOptions({
      stroke: this.color,
    }, 'x', 'y', 'width', 'height', 'fill', 'strokeWidth')))
  }

  addCircle() {
    const radius = this.defaultValue('width') / 2
    this.addShape(new Konva.Circle(this.shapeOptions({
      x: this.defaultValue('x') + radius,
      y: this.defaultValue('y') + radius,
      radius,
      stroke: this.color
    }, 'strokeWidth', 'fill')))
  }

  addMask() {
    if (!this.maskMode) throw new Error('Not in mask mode!')

    const mask = this.addShape(new Konva.Rect(this.shapeOptions({
      name: this.maskName,
      fill: this.default.maskColor,
      draggable: true
    }, 'x', 'y', 'width', 'height')))

    this.maskList.push(mask)
  }

  protected get targetElement() {
    return this._target?.canvas || this.el.nativeElement
  }

  protected setOffset(rects: Rects) {
    const offset = rects.offset!
    asyncScheduler.schedule(() => {
      this.offset = offset
    })
  }

  private addShape<T extends Konva.Shape>(shape: T) {
    this.layer.add(shape)
    this.selector.nodes([shape])
    this.selector.moveToTop()
    return shape
  }

  protected updateSize() {
    const rects = this.calcRects()

    this.setOffset(rects)
    this.stage.size({width: rects.width, height: rects.height})
    this.stage.scale(rects.scale)
  }

  /**
   * Calculate the current drawing area size, position and scale based on the target
   */
  protected calcRects(): Rects {
    const target = this.targetElement
    let rect = target.getBoundingClientRect()
    let result

    if (target instanceof HTMLCanvasElement) {
      const scale = Math.min(rect.width / target.width, rect.height / target.height)
      const width = target.width * scale
      const height = target.height * scale

      result = {
        offset: {
          x: (rect.width - width) / 2,
          y: (rect.height - height) / 2
        },
        width,
        height,
        scale: {x: scale, y: scale}
      }
    } else {
      result = {
        offset: {
          x: 0,
          y: 0
        },
        width: rect.width,
        height: rect.height,
        scale: {x: 1, y: 1}
      }
    }

    this.logger.debug('Calculated drawing rect', target, rect, result)
    return result
  }

  private onClick(e: Konva.KonvaEventObject<any>) {
    if (e.target === this.stage) {
      this.selector.nodes([])
    } else {
      this.selector.nodes([e.target])
    }
  }

  private onMasksChanged() {
    this.masksSubject.next(this.maskList.map(rect => {
      return new DOMRect(
        rect.x(),
        rect.y(),
        rect.width() * rect.scaleX(),
        rect.height() * rect.scaleY()
      )
    }))
  }

  /**
   * Calculate a default value based on the current scale
   */
  private defaultValue(prop: string) {
    const value = this.default[prop]
    return typeof value === 'number' ? value / this.stage.scaleX() : value
  }

  /**
   * Build a shape options object
   * @param options Shape-specific, non-default options
   * @param props List of property names with defaults
   */
  private shapeOptions(options: any, ...props: string[]) {
    options['draggable'] = true
    props.forEach(prop => {
      options[prop] = this.defaultValue(prop)
    })
    return options
  }
}
