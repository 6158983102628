<canvas #videoCanvas
        appDrawingTarget
        class="w-100 h-100 mw-100 mh-100 object-fit-contain"
></canvas>
<video #videoElement
       autoplay
       class="position-absolute invisible"
       [loop]="loop"
       (play)="onPlay()"
       (ended)="onEnded()"
></video>
