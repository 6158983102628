import {Component, ViewChild} from '@angular/core';
import {RoomService} from "../room.service";
import {ErrorService} from "../../common/error.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalComponent} from "../../common/modal/modal.component";
import {ModalButton, ModalConfig} from "../../common/modal/modal";
import {ColorStyle} from "../../common/types";
import {ModalFocusDirective} from "../../common/modal/modal-focus.directive";
import {ToasterService} from "../../common/toaster/toaster.service";
import {map} from "rxjs";

@Component({
  templateUrl: './invite-room-user-modal.component.html',
  styleUrls: ['./invite-room-user-modal.component.scss']
})

export class InviteRoomUserModalComponent extends ModalComponent {
  static readonly modalConfig: ModalConfig = {
    title: 'Invite People',
    style: ColorStyle.Primary,
    buttons: [{text: 'Invite', style: ColorStyle.Primary, action: 'ok'}, 'cancel']
  }

  readonly inviteUserForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.email
    ])
  })

  @ViewChild(ModalFocusDirective) override focusChild?: ModalFocusDirective

  constructor(
    readonly rooms: RoomService,
    readonly error: ErrorService,
    readonly toaster: ToasterService
  ) {
    super()
  }

  inviteUser() {
    this.inviteUserForm.disable()
    return this.error.handleObservable(
      this.rooms.invite({name: this.inviteUserForm.value.name!}),
      {
        errorHandler: () => { this.inviteUserForm.enable() },
        successHandler: () => {
          this.toaster.add({
            type: 'success',
            title: 'Invite People',
            content: `New user ${this.inviteUserForm.value.name} added!`
          })
        }
      }
    )
  }

  override canClick(action: any): boolean {
    if (action === 'ok') {
      return this.inviteUserForm.valid
    }
    return true
  }

  override onButtonClick(action: any) {
    return this.inviteUser().pipe(
      map(() => true)
    )
  }
}
