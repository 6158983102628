import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {WindowComponentImpl} from "../../windows/window-component-impl";
import {ShareManagerService} from "../share-manager.service";
import {Subscriptions} from "../../common/subscriptions";


@Component({
  selector: 'app-share-window',
  templateUrl: './share-window.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareWindowComponent extends WindowComponentImpl implements OnInit, OnDestroy {
  override readonly icon = 'share'
  override readonly title = 'Screen Share'
  private readonly subscriptions = new Subscriptions()

  @Input() state: any


  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    readonly shareManager: ShareManagerService
  ) {
    super()
    this.subscriptions.add(
      shareManager.changed,
      () => this.changeDetector.markForCheck()
    )
  }

  ngOnInit() {
    this.shareManager.setShareWindow(this)
  }

  ngOnDestroy() {
    this.subscriptions.clear()
    this.shareManager.reset()
  }
}
