export type StringMap = {[key: string]: string}
export type AnyMap = {[key: string]: any}

export enum ColorStyle {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Warning = 'warning',
  Success = 'success',
  Dark = 'dark'
}
