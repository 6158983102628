import {Component} from '@angular/core';
import {ConferenceService} from "../conference.service";
import {ShareManagerService} from "../share-manager.service";
import {ConferenceFeature, ConferenceMode} from "../../features/conference.feature";
import {WindowInfo} from "../../windows/window-info";

@Component({
  templateUrl: './conference-feature-buttons.component.html',
  host: {
    class: 'd-flex-row'
  }
})
export class ConferenceFeatureButtonsComponent {
  protected readonly ConferenceMode = ConferenceMode;

  constructor(
    readonly conference: ConferenceService,
    readonly shareManager: ShareManagerService,
    readonly feature: ConferenceFeature
  ) {
  }
}
