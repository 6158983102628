import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'd-contents' }
})
export class ButtonComponent {
  @Input() icon?: string
  @Input() text?: string
  @Input() selected: boolean = false
  @Input() small: boolean = false
  @Input() transparent: boolean = true
  @Input() color?: 'primary' | 'secondary' | 'danger' | 'success'
  @Output() click = new EventEmitter()

  clicked($event: MouseEvent) {
    $event.preventDefault()
    $event.stopPropagation()
    this.click.emit()
    return false
  }

  get buttonClasses(): string[] {
    const result: string[] = []
    if (this.transparent) {
      result.push(
        'app-control',
        'rounded',
        'rounded-2',
        'p-1'
      )
      if (this.color) result.push(`text-${this.color}`)
      if (this.selected) result.push('selected')
    } else {
      result.push(
        'btn',
        `btn-${this.color || 'primary'}`
      )
      if (this.small) result.push('btn-sm')
    }
    return result
  }
}
