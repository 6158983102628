import {finalize, Observable, Observer, Subscription} from "rxjs";

type Handler<T> = Partial<Observer<T>> | ((value: T) => void)

export class Subscriptions {
  private readonly subscriptions = new Map<string, Subscription>()

  set<T>(name: string, op: Observable<T>, handler: Handler<T>): Observable<T> {
    this.remove(name)

    // Subscribe and make sure subscription is removed from map when it completes/errors
    const result = op.pipe(
      finalize(() => {
        this.subscriptions.delete(name)
      })
    ).subscribe(handler)

    this.subscriptions.set(name, result)
    return op
  }

  add<T>(op: Observable<T>, handler: Handler<T>): Observable<T> {
    return this.set(
      window.crypto.randomUUID(),
      op,
      handler
    )
  }

  remove(name: string)  {
    const sub = this.subscriptions.get(name)
    if (sub) {
      sub.unsubscribe()
      return true
    }
    return false
  }

  /**
   * Clear all subscriptions or a group defined by a prefix
   * @param prefix Optional group prefix
   */
  clear(prefix?: string) {
    const subs: Subscription[] = prefix ? [] : Array.from(this.subscriptions.values())

    if (prefix) {
      this.subscriptions.forEach((sub, name) => {
        if (name.startsWith(prefix)) {
          subs.push(sub)
        }
      })
    }

    subs.forEach(sub => sub.unsubscribe())
  }
}
