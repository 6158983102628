import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../api/auth.service";
import {map, take} from "rxjs";

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService)
  const router = inject(Router)

  return authService.authenticated.pipe(
    take(1),
    map(authorized => {
      if (authorized) return true
      router.navigateByUrl('/login').finally()
      return false
    })
  )
};
