<div class="content-container overflow-hidden">
  <ng-content></ng-content>
</div>

<app-drawing
  #drawing
  class="drawing-container overflow-hidden"
  (masks)="masksSubject.next($event)"
></app-drawing>

<div class="annotation-controls d-flex-row app-bg-control p-1">
  <ng-container *ngIf="showMasks; else shapeTools">
    <ng-container *ngIf="drawing.maskMode; then maskTools; else notMaskTools"></ng-container>
  </ng-container>

  <div class="flex-fill"></div>
  <ng-content select="[controls]"></ng-content>
</div>

<ng-template #maskTools>
  <app-button icon="add-mask" title="Add Mask" (click)="drawing.addMask()"></app-button>
  <app-button icon="lock" title="Lock Masks" (click)="drawing.maskMode = false"></app-button>
</ng-template>

<ng-template #notMaskTools>
  <app-button icon="unlock" title="Edit Masks" (click)="drawing.maskMode = true"></app-button>
  <div class="row-divider"></div>
  <ng-container *ngTemplateOutlet="shapeTools"></ng-container>
</ng-template>

<ng-template #shapeTools>
  <app-button icon="shape-rectangle" (click)="drawing.addRectangle()"></app-button>
  <app-button icon="shape-circle" (click)="drawing.addCircle()"></app-button>
  <app-button icon="shape-arrow" (click)="drawing.addArrow()"></app-button>
</ng-template>
