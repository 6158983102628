<button
  type="button"
  class="rounded rounded-3 border border-2 shadow border-light m-auto
        d-flex-column gap-2 align-items-center justify-content-center
        text-bg-dark"
  [style.background-image]="backgroundUrl"
  (click)="createRoom()"
>
  <icon [type]="roomType.icon" class="fs-1"></icon>
  <span class="fs-3">{{ roomType.name }}</span>
</button>
