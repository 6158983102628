import {Component} from '@angular/core';
import {RoomService} from "../../room/room.service";
import {RoomList, RoomModel} from "../../api/models/room.model";
import {ErrorService} from "../../common/error.service";
import {Router} from "@angular/router";
import {AuthService} from "../../api/auth.service";
import {Observable, retry, switchMap, timer} from "rxjs";
import {RoomTypes} from "../../room/room-type";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  protected readonly RoomTypes = RoomTypes;
  protected readonly roomList: Observable<RoomList>

  constructor(
    readonly rooms: RoomService,
    readonly error: ErrorService,
    readonly router: Router,
    readonly auth: AuthService
  ) {
    this.roomList = this.pollRoomList()
  }

  goToRoom(room: RoomModel) {
    this.error.handlePromise(
      this.router.navigate(['/room', room.id])
    )
  }

  pollRoomList() {
    return timer(100, 60000).pipe(
      switchMap(() => this.rooms.list()),
      retry({delay: 5000})
    )
  }

}
