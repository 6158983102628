import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[modal-focus]'
})
export class ModalFocusDirective {
  constructor(public readonly element: ElementRef<HTMLElement>) { }

  focus() {
    this.element.nativeElement.focus({preventScroll: true})
  }
}
