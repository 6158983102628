import {Injectable} from '@angular/core';
import {EMPTY, from, Observable, share} from "rxjs";
import {ToasterService} from "./toaster/toaster.service";
import {ModalService} from "./modal/modal.service";
import {ErrorModalComponent} from "./modal/error-modal/error-modal.component";

export interface ErrorOptions {
  title?: string,
  display?: 'modal' | 'toast'
}

export interface ErrorHandlerOptions extends ErrorOptions {
  successHandler?: { (result: any): void }
  errorHandler?: { (err?: any): void }
  completeHandler?: { (): void }
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    readonly toaster: ToasterService,
    readonly modalService: ModalService
  ) {
  }

  show(
    message: string,
    {title, display}: ErrorOptions = {}
  ): Observable<any> {
    switch (display) {
      case 'toast':
        this.toaster.add({
          title: title || 'Unexpected Error',
          content: message,
          type: 'danger'
        })
        return EMPTY

      default:
        return this.modalService.show({component: ErrorModalComponent, inputs: {message}})
    }
  }

  handleError(err: Error) {
    return this.show(err.message || JSON.stringify(err))
  }

  /**
   * Wraps the observable and displays an error if one is thrown.
   */
  handleObservable<T>(
    op: Observable<T>,
    options: ErrorHandlerOptions = {}
  ) {
    // Use share to make sure we don't unintentionally cause the source
    // to duplicate if the caller also decides to subscribe to when the
    // source completes/emits
    op = op.pipe(share())

    op.subscribe({
      next: val => {
        if (options.successHandler) options.successHandler(val)
      },
      error: err => {
        console.error('Received error', err)
        this.show(err.message || JSON.stringify(err), options).subscribe({
          complete: () => {
            if (options.errorHandler) options.errorHandler(err)
            if (options.completeHandler) options.completeHandler()
          }
        })
      },
      complete: () => {
        if (options.completeHandler) options.completeHandler()
      }
    })

    // Resource shared source so callers can subscribe for complete/output
    return op
  }

  handlePromise<T>(
    op: Promise<T>,
    options: ErrorHandlerOptions = {}
  ): void {
    this.handleObservable(from(op), options)
  }
}
