import {Component, Input} from '@angular/core';
import {ConferenceService} from "../conference.service";
import {WindowComponentImpl} from "../../windows/window-component-impl";

@Component({
  selector: 'app-conference-window',
  templateUrl: './conference-window.component.html',
  styleUrls: ['./conference-window.component.scss']
})
export class ConferenceWindowComponent extends WindowComponentImpl {
  override readonly title = 'Conference'
  override readonly icon = 'conference'

  @Input() state: any

  constructor(
    readonly conference: ConferenceService
  ) {
    super()
  }

  join() {
    this.conference.join()
  }

  leave() {
    this.conference.leave()
  }
}
