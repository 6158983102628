import {Environment} from "../config/environment";
import {LogConfig, Logger, LoggerName, LogLevel} from "./logger";

export abstract class Logging {
  private static readonly DEFAULT_CONFIG = Object.seal<LogConfig>({
    level: Environment.defaultLogLevel || LogLevel.DEBUG
  })
  private static readonly loggerConfigs = Environment.loggers || {}

  static for(category: string, name?: LoggerName) {
    return new Logger(this.configFor(category), name)
  }

  static configFor(category: string): LogConfig {
    return this.loggerConfigs[category] || this.DEFAULT_CONFIG
  }

  // Use from console/UI to dynamically change log levels
  // noinspection JSUnusedGlobalSymbols
  static setLevel(category: string, level: LogLevel) {
    this.loggerConfigs[category] = { level }
  }
}
