import {first, fromEvent, map, merge, Observable} from "rxjs";
import {IDevices} from "./IDevices";
import {EventEmitter} from "@angular/core";
import {observeAudioActivity} from "../audio/audio-activity";

export class AcquiredDevices implements IDevices {
  readonly video?: MediaStreamTrack
  readonly microphone?: MediaStreamTrack
  private videoEnded?: Observable<void>
  private readonly closedEvent = new EventEmitter()

  constructor(readonly stream: MediaStream) {
    this.video = stream.getVideoTracks()[0]
    this.microphone = stream.getAudioTracks()[0]
  }

  get id() {
    return this.stream.id
  }

  get ended() {
    if (!this.video) throw new Error('No video stream')

    if (!this.videoEnded) {
      // Notify ended if the video track ends or close is called to release the streams
      this.videoEnded = merge(fromEvent(this.video, 'ended'), this.closedEvent).pipe(
        first(),
        map(() => undefined)
      )
    }
    return this.videoEnded!
  }

  close() {
    this.closeStream(this.stream)
    this.closedEvent.emit()
  }

  get audioActivity() {
    if (!this.microphone) throw new Error('Audio activity requested with no microphone')
    return observeAudioActivity(this.stream)
  }

  toString() {
    return `{ camera=${this.video?.id || '-'} microphone=${this.microphone?.id || '-'} }`
  }

  private closeStream(stream: MediaStream | undefined) {
    if (stream) {
      for (const track of stream.getTracks()) {
        track.stop()
      }
    }
  }
}
