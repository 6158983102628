<app-bar></app-bar>
<ng-container *ngIf="auth.authenticated | async">
  <div class="container-md">
    <div class="row pt-5">
      <div class="col-12 col-md-6 col-xl-4 mb-5">
        <div class="d-flex-column gap-2 align-items-stretch">
          <ng-container *ngIf="roomList | async as $rooms">
            <ng-container *ngIf="$rooms.total > 0">
              <div class="card text-bg-secondary">
                <h5 class="card-header text-center">
                  Your Rooms
                </h5>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex-row align-items-center gap-5"
                      *ngFor="let $room of $rooms.items">
                    <span class="flex-fill fs-6">{{$room.name}}</span>
                    <button
                      type="button"
                      class="btn text-primary btn-sm"
                      (click)="goToRoom($room)">
                      Join
                    </button>
                  </li>

                </ul>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div class="col-12 col-md-6 col-xl-8">
        <div class="d-flex-row flex-wrap gap-4 justify-content-center">
          <app-new-room-button
            *ngFor="let roomType of RoomTypes"
            [roomType]="roomType"
          ></app-new-room-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
