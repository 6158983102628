import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Modal, ModalButton} from "../modal";
import {asyncScheduler, finalize} from "rxjs";
import {ModalComponent} from "../modal.component";

@Component({
  selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss'],
  host: {
    class: 'position-fixed top-0 start-0 w-100 h-100 bg-black bg-opacity-50 overflow-hidden'
  }
})
export class ModalWindowComponent implements AfterViewChecked {
  private _modal!: Modal
  private component?: ModalComponent
  @ViewChild('modalContent') protected contentRef!: ElementRef<HTMLDivElement>
  protected disableButtons = false
  protected title: string | undefined = undefined
  private shown = false

  get modal() {
    return this._modal
  }

  @Input()
  set modal(value: Modal) {
    this._modal = value
    this.updateTitle()
  }

  setComponent(value: ModalComponent) {
    this.component = value
    // This will set during a view update, so we need to defer
    // setting any other view-dependent properties
    asyncScheduler.schedule(() => this.updateTitle())
  }

  ngAfterViewChecked() {
    this.show()
  }

  private show() {
    if (!this.shown) {
      this.shown = true
      asyncScheduler.schedule(() => {
        this.contentRef.nativeElement.style.top = '0'
        this.component?.focusChild?.focus()
      })
    }
  }

  protected disableButton(button: ModalButton) {
    return this.disableButtons || !this.component?.canClick(button.action)
  }

  protected onButtonClick(button: ModalButton) {
    if (button.action) {
      // Ask the component to handle the action
      // If the result is true or an observable that emits true then close the modal
      const result = this.component!.onButtonClick(button.action)
      const resultHandler = (close: boolean) => {
        if (close) this.modal.close()
      }
      if (typeof result === 'boolean') {
        resultHandler(result)
      } else {
        this.disableButtons = true
        result
          .pipe(finalize(() => { this.disableButtons = false }))
          .subscribe(resultHandler)
      }
    } else {
      // If no action set then close the modal
      this.modal.close()
    }
  }

  @HostListener('click', ['$event'])
  protected onClick(event: Event) {
    if (event.target === event.currentTarget) {
      if (this.modal.canCancel) {
        this.modal.close()
      }
    }
  }

  private updateTitle() {
    this.title = this.component?.title || this._modal.config.title
  }
}
