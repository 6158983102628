import {ConferenceParticipant} from "./conference-participant";
import {ConferenceScreenShare} from "./conference-screen-share";
import {ObservableList} from "../common/observable-list";

interface FindInfo {id: string, screenShare?: boolean, deviceId?: string}

export class ConferenceParticipantList<T extends ConferenceParticipant = ConferenceParticipant>
  extends ObservableList<T>
{
  override add(...participants: T[]) {
    super.add(...participants.filter(p => !this.exists(p)))
  }

  findBy(id: string | FindInfo): T | undefined {
    const info: FindInfo = (typeof id === 'string') ? {id} : id
    return this.find(p => {
      if (info.id !== p.id) return false
      if (p instanceof ConferenceScreenShare) {
        return info.screenShare && info.deviceId === p.device_id
      } else {
        return !info.screenShare
      }
    })
  }

  idIndex(id: string): number {
    return this.findIndex(p => p.id === id)
  }

  idExists(id: string): boolean {
    return this.exists(p => p.id === id)
  }

  removeId(id: string) {
    this.remove(p => p.id === id)
  }
}
