import {CreateWindowModel, UpdateThisWindowModel, UpdateWindowModel, WindowModel} from "../api/models/window.model";
import {Type} from "@angular/core";
import {Feature} from "../features/feature";
import {WindowManagerService} from "./window-manager.service";
import {WindowComponentImpl} from "./window-component-impl";

export type WindowComponentClass = Type<WindowComponentImpl>

export interface WindowInfoOptions {
  feature: Feature
  window: WindowModel
}

export class WindowInfo {
  readonly feature: Feature
  readonly window: WindowModel
  readonly componentClass: WindowComponentClass

  constructor(
    {feature, window}: WindowInfoOptions,
    readonly manager: WindowManagerService
  ) {
    this.feature = feature
    this.window = window
    this.componentClass = feature.componentForWindow(window.name)
  }

  get id() {
    return this.window.id
  }

  get state() {
    return this.window.state
  }

  get position() {
    return this.window.position
  }

  set position(position) {
    if (position !== this.position) this.update({position})
  }

  update(update: UpdateThisWindowModel) {
    this.manager.roomService.channel?.updateWindow(Object.assign({id: this.id}, update))
  }

  updated(update: UpdateWindowModel) {
    Object.assign(this.window, update)
  }

  updateState(state: any, {merge = true}: {merge?: boolean} = {}) {
    const newState = merge ? Object.assign({}, this.state, state) : state
    this.update({state: newState})
  }

  /**
   * Called by the feature or UI component to close a window based on user action
   */
  close() {
    this.destroy()
    this.manager.closed(this)
  }

  /**
   * Called by the window manager when performing a reset
   */
  destroy() {
  }
}
