const ZERO_PADDING = '0'

export function hostWithProtocol(host: string, protocol: 'http' | 'ws' = 'http') {
  const local = host.startsWith('localhost')
  return `${protocol}${local ? '' : 's'}://${host}`
}

export function padLeft(value: any, length: number, char: string = ' '): string {
  let str = value.toString()
  if (str.length < length) {
    str = `${char.repeat((length - str.length) / char.length)}${str}`
  }
  return str
}

export function formatTime(time: Date, {milliseconds}: {milliseconds?: boolean} = {}): string {
  let str = [
    padLeft(time.getHours(), 2, ZERO_PADDING),
    padLeft(time.getMinutes(), 2, ZERO_PADDING),
    padLeft(time.getSeconds(), 2, ZERO_PADDING)
  ].join(':')

  if (milliseconds) {
    str = [
      str,
      padLeft(time.getMilliseconds(), 3, ZERO_PADDING)
    ].join('.')
  }

  return str
}
