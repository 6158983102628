import {Injectable} from '@angular/core';
import {RoomService} from "../room/room.service";
import {Subscriptions} from "../common/subscriptions";
import {WindowPosition} from "../api/models/window.model";
import {WindowInfo, WindowInfoOptions} from "./window-info";
import {FeatureManagerService} from "../features/feature-manager.service";
import {FeatureWindowMessage, RoomChannel, WindowMessage} from "../room/room.channel";
import {BehaviorSubject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class WindowManagerService {
  private readonly windowMap = new Map<string, WindowInfo>
  private readonly orderedWindows: WindowInfo[] = []
  private readonly subscriptions = new Subscriptions()
  private readonly windowsSubject = new BehaviorSubject<WindowInfo[]>([])
  private activeWindow: string | undefined = undefined

  public readonly windows = this.windowsSubject.asObservable()

  constructor(
    readonly roomService: RoomService,
    readonly featureManager: FeatureManagerService
  ) {
    this.subscriptions.set(
      'channel',
      roomService.roomChannel,
      channel => this.onRoomChannelChange(channel)
    )
  }

  get empty() {
    return this.windowMap.size === 0
  }

  get active() {
    return this.activeWindow
  }

  setActive(window: string | { id: string }) {
    const id = typeof(window) === 'string' ? window : window.id
    this.roomService.channel?.activateWindow(id)
  }

  /**
   * Return windows in the normal state
   */
  get normal() {
    return this.orderedWindows.filter(w => w.window.position === WindowPosition.Normal)
  }

  /**
   * Creates a new feature window in the room
   * @param window
   */
  create(window: WindowInfoOptions) {
  }

  /**
   * Adds an existing window during room setup
   * @param window
   */
  add(window: WindowInfoOptions) {
    // if (!this.host) throw new Error('host not set!!!')

    const info = new WindowInfo(window, this)
    this.windowMap.set(info.id, info)
    this.update()

    return info
  }

  closed(window: WindowInfo) {
    this.windowMap.delete(window.id)
    this.update()
  }

  reset() {
    this.windowMap.forEach(window => window.destroy())
    this.windowMap.clear()
    this.update()
  }

  find(feature: string | { type: string }, name: string): WindowInfo | undefined {
    const featureType = typeof feature === 'string' ? feature : feature.type
    return this.orderedWindows.find(w => w.feature.type === featureType && w.window.name === name)
  }

  private onRoomChannelChange(channel: RoomChannel | undefined) {
    if (channel) {
      this.subscriptions.set(
        'channel:updated',
        channel.windowUpdated,
        message => this.onWindowUpdated(message)
      )
      this.subscriptions.set(
        'channel:created',
        channel.windowCreated,
        message => this.onWindowCreated(message)
      )
    } else {
      this.subscriptions.remove('channel:')
    }
  }

  private onWindowUpdated({window}: WindowMessage) {
    const info = this.windowMap.get(window.id)
    info?.updated(window)
  }

  private onWindowCreated({feature, window}: FeatureWindowMessage) {
    this.add({
      feature: this.featureManager.get(feature),
      window
    })
  }

  private update() {
    // Create a new ordered list of windows
    let list = Array.from(this.windowMap.values())
    let newActive: WindowInfo | undefined

    this.orderedWindows.length = list.length
    list
      .sort((a, b) => b.window.order - a.window.order)
      .forEach((val, index) => {
        this.orderedWindows[index] = val

        if (!newActive && val.window.position === WindowPosition.Normal) {
          newActive = val
        }
      })

    this.activeWindow = newActive ? newActive.id : undefined
    this.windowsSubject.next(this.orderedWindows)
  }
}
