import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RoomComponent} from "./room/room/room.component";
import {HomeComponent} from "./home/home/home.component";
import {authGuard} from "./common/auth.guard";
import {DevicesComponent} from "./devices/devices/devices.component";
import {TestDrawingComponent} from "./test/test-drawing/test-drawing.component";
import {TestDevicesComponent} from "./test/test-devices/test-devices.component";

const routes: Routes = [
  {
    path: 'room/:id',
    component: RoomComponent,
    canActivate: [authGuard]
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'devices',
    component: DevicesComponent
  },
  {
    path: 'test',
    children: [
      {
        path: 'annotations',
        component: TestDrawingComponent
      },
      {
        path: 'devices',
        component: TestDevicesComponent
      }
    ]
  },
  {
    path: '',
    pathMatch: "full",
    redirectTo: '/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
