import {Component, TemplateRef, ViewChild} from '@angular/core';
import {RoomService} from "../room.service";
import {ActivatedRoute, Router} from "@angular/router";
import {catchError, Observable, switchMap, tap} from "rxjs";
import {RoomModel} from "../../api/models/room.model";
import {ErrorService} from "../../common/error.service";
import {ToasterService} from "../../common/toaster/toaster.service";
import {ConferenceService} from "../../conference/conference.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
  host: {
    class: 'fullscreen d-flex-column'
  }
})
export class RoomComponent {
  readonly room: Observable<RoomModel>

  @ViewChild('joinConference') joinConferenceTemplate!: TemplateRef<any>

  constructor(
    private readonly roomService: RoomService,
    private readonly route: ActivatedRoute,
    private readonly error: ErrorService,
    private readonly router: Router,
    private readonly toaster: ToasterService,
    private readonly title: Title,
    readonly conference: ConferenceService
  ) {
    this.room = this.route.paramMap.pipe(
      switchMap(params => {
        return this.roomService.join(params.get('id')!)
      }),
      tap(room => {
        this.title.setTitle(`Liaisun - ${room.name}`)
        if (room.conference) {
          this.conference.join(false)
          // this.notifyConference()
        }
      }),
      catchError((err: Error) => {
        return this.error.handleError(err).pipe(
          tap(() => this.router.navigate(['/home']).catch())
        )
      })
    )
  }

  private notifyConference() {
    this.toaster.add({
      title: 'Conference',
      type: 'info',
      content: this.joinConferenceTemplate
    })
  }
}
