import {Directive, ElementRef, EventEmitter, Optional} from '@angular/core';
import {AnnotationsComponent} from "./annotations/annotations.component";
import {Logging} from "../common/logging";

@Directive({
  selector: '[appDrawingTarget]'
})
export class DrawingTargetDirective {
  private readonly resizedEvent = new EventEmitter(true)
  readonly element: HTMLCanvasElement | HTMLVideoElement
  readonly canvas?: HTMLCanvasElement

  constructor(
    el: ElementRef<HTMLCanvasElement | HTMLVideoElement>,
    @Optional() annotations: AnnotationsComponent
  ) {
    this.element = el.nativeElement
    if (this.element instanceof HTMLCanvasElement) {
      this.canvas = this.element
    }

    const logger = Logging.for('annotations', 'drawing-target')
    if (annotations) {
      logger.debug('Attaching to annotations')
      annotations.annotationTarget = this
    } else {
      logger.debug('No annotations found', this.element)
    }
  }

  get resized() {
    return this.resizedEvent.asObservable()
  }

  /**
   * Wrapper for resize the attached canvas. This allows for us to notify
   * of canvas size changes which the native canvas does not do.
   */
  resize(width: number, height: number) {
    if (this.canvas && (this.canvas.width != width || this.canvas.height != height)) {
      this.canvas.width = width
      this.canvas.height = height
      this.resizedEvent.emit()
    }
  }
}
