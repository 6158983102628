<ng-template #content>
  <ng-container
    *ngComponentOutlet="windowInfo.componentClass; inputs: { state: window.state }"
  ></ng-container>
</ng-template>

<ng-container *ngIf="!docked; else content">
  <div class="window-title w-100 mw-100 d-flex-row gap-2 px-2 py-1 user-select-none fs-6"
       (pointerdown)="onPointerDown($event, 'move')"
  >
    <icon *ngIf="component?.icon" [type]="component!.icon!"></icon>
    <div>{{component?.title || (window.name | titlecase) }}</div>
    <div class="flex-fill"></div>
    <icon *ngIf="component?.canClose" type="x"></icon>
  </div>

  <ng-container [ngTemplateOutlet]="content"></ng-container>
<!--  <div class="window-content flex-fill overflow-hidden">-->
<!--  </div>-->

  <div
    *ngIf="component?.controls || gridMode"
    class="window-controls d-flex-row ps-2 pe-1 py-1 gap-1">
    <div class="flex-fill">
      <ng-container *ngIf="component?.controls">
        <ng-container *ngTemplateOutlet="component!.controls!"></ng-container>
      </ng-container>
    </div>

    <div class="resize" *ngIf="gridMode" (pointerdown)="onPointerDown($event, 'resize')">
      <icon class="window-resize" type="three-dots-vertical"></icon>
    </div>
  </div>
</ng-container>
