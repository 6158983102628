<div class="participant-container w-100 h-100">
  <ng-template #noAudio>
    <div *ngIf="!participant.self" class="position-absolute start-0 bottom-0 m-2 text-danger">
      <icon type="microphone-off"></icon>
    </div>
  </ng-template>

  <ng-template #noVideo>
    <div class="position-absolute top-50 start-50 translate-middle fs-3 text-center">
      {{participant.name || participant.id | emailName}}
    </div>
  </ng-template>

  <ng-template #noMedia>
    <ng-container *ngTemplateOutlet="noVideo"></ng-container>
    <ng-container *ngTemplateOutlet="noAudio"></ng-container>
  </ng-template>

  <ng-container *ngIf="participant.stream | async as $stream; else noMedia">
    <ng-container *ngIf="participant.hasVideo; else audioOnly">
      <video class="position-relative w-100 h-100 object-fit-cover" autoplay [srcObject]="$stream"></video>
    </ng-container>
    <ng-template #audioOnly>
      <ng-container *ngTemplateOutlet="noVideo"></ng-container>
      <audio autoplay [srcObject]="$stream" class="d-none"></audio>
    </ng-template>
  </ng-container>
</div>
