import {ConferenceJoinInfo, ConferenceService} from "../conference.service";
import {map, Observable} from "rxjs";
import {ConferenceProvider} from "../conference-provider";
import {AwsConferenceSession} from "./aws-conference-session";
import {ConferenceSession} from "../conference-session";
import {IDevices} from "../../devices/IDevices";
import {Stage} from "amazon-ivs-web-broadcast";

export interface AwsJoinInfo extends ConferenceJoinInfo {
  stage: string
  token: string
  // Flags for test behavior
  test?: {
    // Override AWS Stage class with mock
    newStage?: (token: string, provider: AwsConferenceSession) => Stage,
    // Disable setting up events
    listen?: boolean
  }
}

export class AwsConferenceProvider implements ConferenceProvider<AwsJoinInfo> {
  constructor(
    protected readonly conference: ConferenceService
  ) {
  }

  join(joinInfo: AwsJoinInfo): Observable<ConferenceSession>
  join(joinInfo: AwsJoinInfo, options: { devices: IDevices }): Observable<ConferenceSession>
  join(joinInfo: AwsJoinInfo, options: { devices: IDevices, screenShare: true }): Observable<ConferenceSession>
  join(
    joinInfo: AwsJoinInfo,
    {devices, screenShare = false}: { devices?: IDevices, screenShare?: boolean } = {}
  ): Observable<ConferenceSession> {
    const session = new AwsConferenceSession(this.conference, devices, screenShare)
    return session.join(joinInfo).pipe(map(() => session))
  }
}
