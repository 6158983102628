import {Channel, ChannelMessage} from "../api/channel";
import {CreateWindowModel, UpdateWindowModel, WindowModel} from "../api/models/window.model";
import {EventEmitter} from "@angular/core";
import {NamedEntityModel} from "../api/models/named-entity.model";
import {EntityModel} from "../api/models/entity.model";
import {FeatureModel, UpdateFeatureModel} from "../api/models/feature.model";
import {Logging} from "../common/logging";

interface RoomMessage extends ChannelMessage {
}

export interface FeatureMessage extends RoomMessage {
  feature: FeatureModel
}

export interface WindowMessage extends RoomMessage {
  window: WindowModel
}

export interface FeatureWindowMessage extends WindowMessage {
  feature: string
}

export interface UserMessage extends RoomMessage {
  user: NamedEntityModel | EntityModel
}

export class RoomChannel extends Channel<RoomMessage> {
  readonly featureUpdated = new EventEmitter<FeatureMessage>(true)
  readonly windowCreated = new EventEmitter<FeatureWindowMessage>(true)
  readonly windowUpdated = new EventEmitter<WindowMessage>(true)
  readonly roomJoined = new EventEmitter<UserMessage>(true)
  readonly roomLeft = new EventEmitter<UserMessage>(true)
  readonly conferenceStarted = new EventEmitter<RoomMessage>(true)
  readonly channelId = window.crypto.randomUUID()

  protected logger = Logging.for('room', 'room-channel')

  constructor() {
    super();
  }

  updateFeature(feature: UpdateFeatureModel) {
    this.perform('update_feature', {feature})
  }

  createWindow(data: { feature: string, window: CreateWindowModel}): void {
    this.perform('create_window', data)
  }

  updateWindow(data: UpdateWindowModel): void {
    this.perform('update_window', data)
  }

  activateWindow(id: string): void {
    this.perform('activate_window', {id})
  }
}
