import {Component, Input} from '@angular/core';
import {ConferenceParticipant} from "../conference-participant";
import {AutoGridItemDirective} from "../../common/auto-grid/auto-grid-item.directive";

@Component({
  selector: 'app-conference-participant',
  templateUrl: './conference-participant.component.html',
  styleUrls: ['./conference-participant.component.scss'],
  hostDirectives: [AutoGridItemDirective],
  host: {
    class: 'position-relative overflow-hidden user-select-none'
  }
})
export class ConferenceParticipantComponent {
  @Input() participant!: ConferenceParticipant

}
