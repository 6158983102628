import {ConferenceParticipant, ConferenceParticipantOptions} from "./conference-participant";

export class ConferenceScreenShare extends ConferenceParticipant {
  constructor(
    id: string,
    readonly device_id: string,
    user_id: string,
    options: ConferenceParticipantOptions = {}
  ) {
    super(id, user_id, options);
  }
}

