// @ts-ignore
import * as ActionCable from '@rails/actioncable';
import {BehaviorSubject} from "rxjs";
import {SyncSubscription} from "./sync.service";
import {AnyMap} from "../common/types";
import {setUpLocationSync} from "@angular/router/upgrade";
import {Logger} from "../common/logger";

export enum ChannelState {
  Pending,
  Connected,
  Disconnected,
  Failed
}

export interface ChannelMessage {
  readonly action: string
}

export abstract class Channel<T extends ChannelMessage> {
  private readonly _state = new BehaviorSubject<ChannelState>(ChannelState.Pending)
  readonly state = this._state.asObservable()

  private subscription?: SyncSubscription
  protected abstract logger: Logger

  protected constructor() {
  }

  received(data: T): void {
    const action = data.action

    if (action) {
      // @ts-ignore
      const actionHandler = this[this.normalizeActionName(action)]

      if (!actionHandler) {
        this.logger.error(`No receiver defined for ${action}`)
      } else if (typeof actionHandler === 'function') {
        actionHandler(data)
      } else if ('emit' in actionHandler) {
        actionHandler.emit(data)
      } else {
        throw new Error('Unknown action receiver type')
      }
    } else {
      this.logger.error('Message received with no type')
    }
  }

  perform(action: string, body: AnyMap) {
    if (Object.isSealed(body)) body = Object.assign({}, body)
    this.subscription?.perform(action, body)
  }

  disconnect() {
    if (this._state.value === ChannelState.Connected) {
      this.subscription?.consumer.subscriptions.remove(this.subscription)
    }
  }

  initialized(subscription: SyncSubscription): void {
    this.subscription = subscription
    this.logger.debug('INITIALIZED')
  }

  connected(): void {
    this.logger.debug('CONNECTED')
    this._state.next(ChannelState.Connected)
  }

  disconnected(): void {
    this.logger.debug('DISCONNECTED')
    this._state.next(ChannelState.Disconnected)
  }

  rejected(): void {
    this.logger.debug('REJECTED')
    this._state.next(ChannelState.Failed)
  }

  private normalizeActionName(name: string) {
    return name.replaceAll(/_[a-z]/g, match => {
      return match.substring(1).toUpperCase()
    })
  }
}
