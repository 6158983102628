<app-button icon="microphone-on"></app-button>
<app-button icon="video"></app-button>

<div class="row-divider"></div>

<app-button
  icon="share"
  title="Share"
  (click)="shareManager.capture()"
></app-button>

<app-button
  *ifLocal
  icon="add-fake"
  title="Add Fake Participant"
  (click)="conference.addFakeParticipant()"
></app-button>

<div class="row-divider"></div>

<ng-container *ngIf="feature.mainWindowVisible">
  <app-button
    icon="conference-gallery"
    title="Gallery Mode"
    [selected]="feature.mode === ConferenceMode.Gallery"
    (click)="feature.mode = ConferenceMode.Gallery"
  ></app-button>

  <app-button
    icon="conference-speaker"
    title="Speaker Mode"
    [selected]="feature.mode === ConferenceMode.Speaker"
    (click)="feature.mode = ConferenceMode.Speaker"
  ></app-button>
</ng-container>
