import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {catchError, Observable, of} from "rxjs";
import {UserModel} from "./models/user.model";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly api: ApiService
  ) { }

  get(): Observable<UserModel | null> {
    return this.api.get<UserModel>('user').pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) return of(null)
        throw err
      })
    )
  }
}
