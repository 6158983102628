import {Component} from '@angular/core';

@Component({
  templateUrl: './test-drawing.component.html',
  host: {
    class: 'p-3 d-flex-column gap-3'
  }
})
export class TestDrawingComponent {
  protected readonly smallSize = 400
  protected readonly largeSize = 600

  protected largeWindow = true
  protected largeCanvas = false

  protected canvasStream?: MediaStream
}
