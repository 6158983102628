import {Injectable} from '@angular/core';
import {UserModel} from "./models/user.model";
import {map, Observable, of} from "rxjs";
import {UserService} from "./user.service";
import {ApiService} from "./api.service";
import {hostWithProtocol} from "../common/utilities";
import {Environment} from "../config/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private state: 'authenticated' | 'anonymous' | 'unknown' = 'unknown'
  private _user?: UserModel
  private loadUserOperation?: Observable<boolean>

  constructor(
    private readonly userService: UserService
  ) { }

  get user() {
    return this._user
  }

  get authenticated(): Observable<boolean> {
    switch (this.state) {
      case 'authenticated':
        return of(!!this.user)
      case 'anonymous':
        return of(false)
      default:
        return this.loadUser()
    }
  }

  login() {
    const params = new URLSearchParams()
    params.set('redirect_uri', this.authRedirectUri)
    window.location.href = ApiService.urlFor(`auth/default?${params.toString()}`)
  }

  logout(): void {
    const params = new URLSearchParams()
    params.set('redirect_uri', this.authRedirectUri)
    window.location.href = ApiService.urlFor(`auth/logout?${params.toString()}`)
  }

  private get authRedirectUri() {
    return `${hostWithProtocol(Environment.host)}/home`
  }

  private loadUser(): Observable<boolean> {
    this.loadUserOperation = this.loadUserOperation || this.userService.get().pipe(
      map(user => {
        delete this.loadUserOperation
        if (user) {
          this._user = user
          this.state = 'authenticated'
          return true
        }
        this.state = 'anonymous'
        return false
      })
    )

    return this.loadUserOperation
  }
}
