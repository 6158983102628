import {Component} from '@angular/core';
import {AuthService} from "../api/auth.service";
import {DevicesComponent} from "../devices/devices/devices.component";
import {ModalService} from "../common/modal/modal.service";

@Component({
  selector: 'app-bar',
  templateUrl: './app-bar.component.html',
  host: {
    class: 'text-bg-dark bg-gradient d-flex-row align-items-center py-1 px-3 gap-2'
  }
})
export class AppBarComponent {
  constructor(
    readonly auth: AuthService,
    readonly modalService: ModalService
  ) {
  }

  showDevices() {
    this.modalService.show({component: DevicesComponent})
  }
}
