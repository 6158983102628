import {StageParticipantInfo, StageStream, StreamType} from "amazon-ivs-web-broadcast";
import {ConferenceScreenShare} from "../conference-screen-share";
import {ConferenceParticipant} from "../conference-participant";
import {AwsConferenceSession} from "./aws-conference-session";
import {ConferenceParticipantList} from "../conference-participant-list";

export class AwsConferenceParticipant {
  readonly userId: string
  readonly name: string
  readonly deviceId?: string
  readonly screenShare: boolean
  readonly self: boolean

  constructor(
    private readonly session: AwsConferenceSession,
    participantInfo: StageParticipantInfo
  ) {
    const {name, device_id, type} = participantInfo.attributes
    this.userId = participantInfo.userId
    this.name = name as string
    this.deviceId = device_id as string
    this.screenShare = type === 'screen-share'
    this.self = this.userId === session.conference.self
  }

  get id() { return this.userId }
  get showLocal() { return !(this.self && this.screenShare) }

  add() {
    if (!this.find()) {
      this.list.add(this.toParticipant())
    }
  }

  remove() {
    const found = this.find()
    if (found) {
      found.resetMedia()
      this.list.remove(found)
    }
  }

  find() {
    return this.list.findBy(this)
  }

  setStreams(streams: StageStream[]) {
    const participant = this.find()
    if (participant) {
      if (this.self) {
        // Ignore self audio streams
        streams = streams.filter(s => s.streamType === StreamType.VIDEO)
      }
      participant.setTracks(streams.map(ss => ss.mediaStreamTrack))
    }
  }

  resetStreams() {
    this.find()?.resetMedia()
  }

  toParticipant() {
    if (this.screenShare) {
      return new ConferenceScreenShare(
        this.userId,
        this.deviceId!,
        this.name,
        {self: this.self}
      )
    } else {
      return new ConferenceParticipant(
        this.userId,
        this.name,
        {self: this.self}
      )
    }
  }

  toString() {
    return `${this.name}${this.self ? ' (self)' : ''}${this.screenShare ? ' SCREEN' : ''}`
  }

  private get list() {
    return (
      this.screenShare ? this.session.conference.screens : this.session.conference.participants
    ) as ConferenceParticipantList
  }
}

export function awsParticipant(session: AwsConferenceSession, info: StageParticipantInfo) {
  return new AwsConferenceParticipant(session, info)
}
