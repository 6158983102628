import {Component, Input} from '@angular/core';
import {ConferenceService} from "../conference.service";
import {WindowComponentImpl} from "../../windows/window-component-impl";

@Component({
  templateUrl: './conference-gallery.component.html',
  styleUrls: ['./conference-gallery.component.scss'],
  host: {
    class: 'd-flex-column h-100 mh-100 overflow-auto'
  }
})
export class ConferenceGalleryComponent extends WindowComponentImpl {
  @Input() state: any

  constructor(
    readonly conference: ConferenceService
  ) {
    super()
  }


}
