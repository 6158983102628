import {Injectable} from '@angular/core';
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {catchError, EMPTY, from, fromEvent, map, Observable, of, switchMap, tap} from "rxjs";
import {AcquiredDevices} from "./acquired-devices";
import {Logging} from "../common/logging";
import {LogLevel} from "../common/logger";
import {ToasterService} from "../common/toaster/toaster.service";
import {ModalService} from "../common/modal/modal.service";
import {DevicesComponent} from "./devices/devices.component";

// noinspection SpellCheckingInspection
export enum DeviceType {
  Camera = 'videoinput',
  Microphone = 'audioinput'
}

export class DeviceInfo {
  readonly id: string
  readonly name: string
  readonly type: DeviceType

  constructor(deviceInfo: MediaDeviceInfo) {
    this.id = deviceInfo.deviceId
    this.type = deviceInfo.kind as DeviceType
    this.name = deviceInfo.label
  }
}

interface AcquireDevice {
  id: string
  resolution?: number
}

export interface AcquireOptions {
  video?: AcquireDevice
  microphone?: AcquireDevice
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  readonly deviceChange: Observable<void>

  private readonly MAX_CAMERA_RESOLUTION = 720
  private readonly MAX_CAMERA_FRAMERATE = 30
  private readonly MAX_SCREEN_RESOLUTION = 1080
  private readonly MAX_SCREEN_FRAMERATE = 15
  private readonly MICROPHONE_SAMPLE_SIZE = 16
  private readonly ALLOWED_DEVICE_TYPES = new Set([DeviceType.Camera, DeviceType.Microphone])

  private hasPermission = false
  private readonly logger = Logging.for('devices', 'device-service')

  constructor(
    private readonly toaster: ToasterService,
    private readonly modalService: ModalService
  ) {
    this.deviceChange = fromEvent(navigator.mediaDevices, 'devicechange').pipe(map(_e => undefined))
  }

  /**
   * Attempt to acquired requested devices. Only emits a value if successful.
   * @param video
   * @param microphone
   * @param speaker
   */
  acquire({video, microphone}: AcquireOptions): Observable<AcquiredDevices> {
    const constraints: MediaStreamConstraints = {}

    if (video) {
      constraints.video = this.cameraConstraints(video)
    }
    if (microphone) {
      constraints.audio = this.microphoneConstraints(microphone)
    }

    return fromPromise(navigator.mediaDevices.getUserMedia(constraints)).pipe(
      map(stream => {
        const devices = new AcquiredDevices(stream)
        this.logger.debug('Successfully acquired devices', devices)
        return devices
      }),
      catchError(err => {
        this.logger.error('Failed to acquire devices', err)
        return EMPTY
      })
    )
  }

  acquireDefault() {
    return this.devices().pipe(
      switchMap(devices => {
        const camera = devices.find(device => device.type === DeviceType.Camera)
        const microphone = devices.find(device => device.type === DeviceType.Microphone)
        const options: AcquireOptions = {}

        if (camera) options.video = {id: camera.id}
        if (microphone) options.microphone = {id: microphone.id}

        return this.acquire(options)
      })
    )
  }

  acquirePrompt() {
    return this.prompt().pipe(
      switchMap(options => this.acquire(options))
    )
  }

  /**
   * Return a list of available devices
   * @param type Optional device type filter
   */
  devices(type?: DeviceType): Observable<DeviceInfo[]> {
    return this.getPermission().pipe(
      switchMap(allowed => {
        if (allowed) {
          return from(navigator.mediaDevices.enumerateDevices()).pipe(
            map(devices => {
              return devices
                .map(mediaDeviceInfo => new DeviceInfo(mediaDeviceInfo))
                .filter(deviceInfo => this.isAllowedDevice(deviceInfo, type))
            }),
            tap(devices => {
              // Dump device list for diagnostics
              if (this.logger.isLevel(LogLevel.DEBUG)) {
                devices.forEach(deviceInfo => this.logger.debug('Found device', deviceInfo))
              }
            })
          )
        } else {
          return EMPTY
        }
      })
    )
  }

  /**
   * Capture user screen or window
   */
  capture(): Observable<AcquiredDevices> {
    return fromPromise(navigator.mediaDevices.getDisplayMedia(this.displayConstraints())).pipe(
      map(stream => new AcquiredDevices(stream)),
      catchError(() => EMPTY)
    )
  }

  prompt(): Observable<AcquireOptions> {
    return this.modalService.show(DevicesComponent)
  }

  private getPermission(): Observable<boolean> {
    if (this.hasPermission) {
      return of(true)
    } else {
      const constraints: MediaStreamConstraints = { video: true, audio: true }

      return from(navigator.mediaDevices.getUserMedia(constraints)).pipe(
        catchError(err => {
          // Log and retry with microphone only
          this.logger.warn('Failed to get camera and audio permissions. Retrying with audio-only.', err)
          constraints.video = false
          return from(navigator.mediaDevices.getUserMedia(constraints))
        }),
        map(stream => {
          new AcquiredDevices(stream).close()
          if (!constraints.video) {
            this.toaster.add({
              type: 'warning',
              title: 'Device Access',
              content: 'No cameras were available. Some features may be unavailable.'
            })
          }
          this.hasPermission = true
          return true
        }),
        catchError(err => {
          this.logger.warn('Failed to get device permissions', err)
          this.toaster.add({
            type: 'warning',
            title: 'Device Access',
            content: 'Permission was denied to your camera and microphone. Some features may be unavailable.'
          })
          return of(false)
        })
      )
    }
  }

  private isAllowedDevice(deviceInfo: DeviceInfo, type?: DeviceType): boolean {
    return deviceInfo.id.length > 0
      && this.ALLOWED_DEVICE_TYPES.has(deviceInfo.type)
      && (!type || type === deviceInfo.type)
  }

  private cameraConstraints(device: AcquireDevice): MediaTrackConstraints {
    return {
      deviceId: {
        exact: device.id,
      },
      height: {
        max: device.resolution || this.MAX_CAMERA_RESOLUTION
      },
      frameRate: {
        max: this.MAX_CAMERA_FRAMERATE
      }
    }
  }

  private microphoneConstraints(device: AcquireDevice): MediaTrackConstraints {
    return {
      deviceId: {
        exact: device.id
      },
      sampleSize: {
        ideal: this.MICROPHONE_SAMPLE_SIZE
      }
    }
  }

  private displayConstraints(): DisplayMediaStreamOptions {
    return {
      video: {
        height: {
          max: this.MAX_SCREEN_RESOLUTION
        },
        frameRate: {
          ideal: this.MAX_SCREEN_FRAMERATE
        }
      }
    }
  }
}
