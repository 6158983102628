import {Injectable} from '@angular/core';
import {Toast, ToasterComponent} from "./toaster.component";
import {timer} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  private readonly LONG_TIMEOUT = 20
  private readonly SHORT_TIMEOUT = 10

  toasterComponent?: ToasterComponent

  constructor() { }

  get toasts() {
    return this.toasterComponent?.toasts
  }

  add(toast: Toast) {
    this.toasts?.push(toast)

    const timeout = (toast.timeout === 'long') ? this.LONG_TIMEOUT : this.SHORT_TIMEOUT
    timer(timeout * 1000)
      .subscribe({ complete: () => this.dismiss(toast) })
  }

  dismiss(toast: Toast) {
    if (this.toasts) {
      const index = this.toasts.indexOf(toast)
      if (index >= 0) this.toasts.splice(index, 1)
    }
  }
}
