export enum RoomType {
  Conference = 'conference',
  Medical = 'medical'
}

export interface RoomTypeDef {
  readonly type: RoomType
  readonly name: string
  readonly icon: string
  readonly image: string
}

export const RoomTypes: RoomTypeDef[] = [
  {
    type: RoomType.Conference,
    name: 'Conference',
    icon: 'conference',
    image: 'conference.png'
  },
  {
    type: RoomType.Medical,
    name: 'Medical',
    icon: 'medical',
    image: 'medical.png'
  }
]
