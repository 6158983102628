import {interval, map, Observable, shareReplay, throwError} from "rxjs";

import {AudioActivityState} from "../audio-activity-state";

export class AudioActivityAnalyzer {
  protected readonly pollInterval = 200

  private readonly sampleRate: number
  protected context?: AudioContext
  private analyser?: AnalyserNode
  private microphone?: MediaStreamAudioSourceNode
  private observable?: Observable<AudioActivityState>
  readonly state: AudioActivityState = {active: false, level: 0}

  constructor(
    readonly stream: MediaStream
  ) {
    const track = stream.getAudioTracks()[0]
    if (!track) throw new Error('Invalid audio stream')

    this.sampleRate = track.getSettings().sampleRate!
  }

  observe() {
    if (!this.observable) {
      this.observable = new Observable<AudioActivityState>(subscriber => {
        const sub = this.pollAudioActivity().subscribe({
          next: value => {
            this.state.level = value
            subscriber.next(this.state)
          },
          error: err => {
            this.cleanup()
            subscriber.error(err)
          },
          complete: () => {
            this.cleanup()
            subscriber.complete()
          }
        })

        sub.add(() => this.cleanup())

        return function unsubscribe() {
          sub.unsubscribe()
        }
      }).pipe(
        shareReplay({refCount: true, bufferSize: 1}),
      )
    }

    return this.observable!
  }

  protected cleanup() {
    this.analyser?.disconnect()
    delete this.analyser
    this.microphone?.disconnect()
    delete this.microphone
    this.context?.close().finally()
    delete this.context
  }

  protected pollAudioActivity(): Observable<number> {
    try {
      const maxByteValue = 0xff
      const maxVolume = 100

      this.context = this.createContext()
      this.analyser = this.context.createAnalyser();
      this.microphone = this.context.createMediaStreamSource(this.stream);

      this.analyser.smoothingTimeConstant = 0.8;
      this.analyser.fftSize = 1024;
      this.microphone.connect(this.analyser);

      return interval(this.pollInterval).pipe(
        map(() => {
          const array = new Uint8Array(this.analyser!.frequencyBinCount);
          this.analyser!.getByteFrequencyData(array);
          const total = array.reduce((sum, value) => sum + (value / maxByteValue), 0)
          const volume = total / array.length * maxVolume

          // console.info(`Detected audio level ${volume}`)
          return Math.round(volume)
        })
      )
    } catch (err) {
      return throwError(() => err)
    }
  }

  protected createContext() {
    return new AudioContext({sampleRate: this.sampleRate})
  }
}
