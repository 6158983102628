import {EventEmitter, Type} from "@angular/core";
import {ColorStyle} from "../types";
import {ModalComponent} from "./modal.component";

export interface ModalButton {
  style?: ColorStyle
  text: string
  action?: any
}

interface PredefinedButtons {
  ok: ModalButton
  close: ModalButton
  cancel: ModalButton
  yes: ModalButton
  no: ModalButton
}

const BUTTONS: PredefinedButtons = {
  close: {text: 'Close'},
  ok: {text: 'OK', style: ColorStyle.Primary, action: 'ok'},
  cancel: {text: 'Cancel', style: ColorStyle.Danger},
  yes: {text: 'Yes', style: ColorStyle.Success, action: 'yes'},
  no: {text: 'No', style: ColorStyle.Danger, action: 'no'},
}

export type NamedModalButton = keyof PredefinedButtons
export type ModalButtonsDef = (NamedModalButton | ModalButton)[]

export interface ModalInfo<C extends ModalComponent> {
  component: Type<C>
  inputs?: any
}

export interface ModalConfig {
  title?: string
  style?: ColorStyle
  buttons?: (NamedModalButton | ModalButton)[]
}

export class Modal<C extends ModalComponent = ModalComponent> {
  readonly id = window.crypto.randomUUID()
  readonly component: Type<C>
  readonly inputs: any
  readonly config: ModalConfig
  readonly closed = new EventEmitter<any>()
  readonly buttons: ModalButton[]
  readonly style: ColorStyle
  readonly canCancel: boolean

  constructor({component, inputs}: ModalInfo<C>) {
    this.component = component
    this.inputs = inputs || {}

    if ('modalConfig' in this.component) {
      this.config = this.component.modalConfig as ModalConfig
    } else {
      this.config = {}
    }
    this.buttons = Modal.buttons(this.config.buttons || [])
    this.canCancel = this.buttons.findIndex(b => !b.action) >= 0
    this.style = this.config.style || ColorStyle.Primary
  }

  static buttons(def: ModalButtonsDef): ModalButton[] {
    if (!def || def.length <= 0) def = ['close']
    return def.map(it => {
      return typeof it === 'string' ? BUTTONS[it] : it
    })
  }

  close(action?: any) {
    if (action !== undefined) this.closed.emit(action)
    this.closed.complete()
  }
}
