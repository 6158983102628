import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable, of} from "rxjs";
import {AutoGridItemDirective} from "../../common/auto-grid/auto-grid-item.directive";
import {ConferenceFeature} from "../../features/conference.feature";
import {ScreenCapture} from "../screen-capture";
import {ConferenceParticipant} from "../conference-participant";

@Component({
  selector: 'app-share-window-item',
  templateUrl: './share-window-item.component.html',
  hostDirectives: [AutoGridItemDirective],
  host: {
    class: 'd-flex position-relative rounded overflow-hidden justify-content-center',
    style: 'background-color: #101010;'
  }
})
export class ShareWindowItemComponent {
  private readonly closeEvent = new EventEmitter(true)
  private _capture?: ScreenCapture

  @Input() participant?: ConferenceParticipant
  @Output() readonly close = this.closeEvent.asObservable()

  constructor(
    protected readonly conference: ConferenceFeature
  ) {
  }

  get capture() {
    return this._capture
  }

  @Input()
  set capture(value) {
    this._capture = value
  }

  protected closeWindow() {
    this.closeEvent.emit()
  }

  protected stop() {
    this._capture?.stop()
  }

  /**
   * Stream that should be used for the video source
   */
  protected get videoStream(): Observable<MediaStream | undefined> {
    if (this.capture) {
      return this.conference.secure ? this.capture.capture : this.capture.output
    } else if (this.participant) {
      return this.participant.stream
    } else {
      return of(undefined)
    }
  }
}
