import {Component} from '@angular/core';
import {RoomService} from "../room.service";
import {ModalService} from "../../common/modal/modal.service";
import {InviteRoomUserModalComponent} from "../invite-room-user-modal/invite-room-user-modal.component";
import {FeatureManagerService} from "../../features/feature-manager.service";

@Component({
  selector: 'app-room-host-controls',
  templateUrl: './room-host-controls.component.html',
  styleUrls: ['room-host-controls.component.scss'],
  host: {
    class: 'd-flex-row app-control p-2 gap-1 bg-gradient'
  }
})
export class RoomHostControlsComponent {
  readonly apps = Object.seal(['video', 'audio', 'conference', 'share'])

  constructor(
    private readonly roomService: RoomService,
    private readonly modalService: ModalService,
    readonly featureManager: FeatureManagerService
  ) {
  }

  invite() {
    this.modalService.show(InviteRoomUserModalComponent)
  }

  leave() {
    this.roomService.leave().subscribe()
  }
}
