import {AfterViewInit, Component, Input, Optional, ViewChild} from '@angular/core';
import {DrawingComponent} from "../drawing.component";
import {BehaviorSubject} from "rxjs";
import {DrawingTargetDirective} from "../drawing-target.directive";
import {FullScreenDirective} from "../../common/full-screen.directive";

@Component({
  selector: 'app-annotations',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.scss'],
  host: {
    class: 'd-grid fill',
  }
})
export class AnnotationsComponent implements AfterViewInit {
  @Input() showMasks = false
  @ViewChild(DrawingComponent) private drawing!: DrawingComponent

  protected readonly masksSubject = new BehaviorSubject<DOMRect[]>([])
  readonly masks = this.masksSubject.asObservable()

  private _annotationTarget?: DrawingTargetDirective

  constructor(
    @Optional() readonly fullScreen: FullScreenDirective
  ) {
  }

  set annotationTarget(el: DrawingTargetDirective) {
    this._annotationTarget = el
  }

  ngAfterViewInit() {
    if (this._annotationTarget) {
      this.drawing.target = this._annotationTarget
    }
  }
}
