import {Directive} from '@angular/core';
import {Environment} from "../config/environment";
import {IfDirectiveBase} from "./if-directive-base";

@Directive({
  selector: '[ifLocal]',
  standalone: true
})
export class IfLocalDirective extends IfDirectiveBase {
  constructor() {
    super()
    this.enabled = Environment.api.host.startsWith('localhost')
  }
}
