import {Injectable, Type} from '@angular/core';
import {Modal, ModalInfo} from "./modal";
import {Subscriptions} from "../subscriptions";
import {ObservableList} from "../observable-list";
import {ModalComponent} from "./modal.component";

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  readonly modals = new ObservableList<Modal>()
  private readonly subscriptions = new Subscriptions()

  constructor() { }

  show<C extends ModalComponent>(info: ModalInfo<C> | Type<C>) {
    const modal = new Modal('component' in info ? info : {component: info})
    this.modals.add(modal)
    return this.subscriptions.add(modal.closed, {
      complete: () => {
        this.modals.remove(modal)
      }
    })
  }

  cancelTop() {
    const topModal = this.modals.last
    if (topModal && topModal.canCancel) {
      topModal.close()
    }
  }
}
