import {AfterViewInit, Component, ElementRef, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {asyncScheduler, map} from "rxjs";
import {RoomService} from "../../room/room.service";
import {WindowManagerService} from "../window-manager.service";
import {WindowPosition} from "../../api/models/window.model";
import {WindowInfo} from "../window-info";

export interface Position {
  x: number
  y: number
}

export enum WindowLayoutMode {
  Simple = 'simple',
  Grid = 'grid'
}

interface WindowList {
  list: WindowInfo[]
  showEmpty?: boolean
}

@Component({
  selector: 'app-window-layout',
  templateUrl: './window-layout.component.html',
  styleUrls: ['./window-layout.component.scss'],
  host: {
    class: 'd-grid fill'
  }
})
export class WindowLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly GRID_WIDTH = 20
  readonly GRID_HEIGHT = 15
  readonly WINDOW_MIN_SIZE = 5
  readonly WINDOW_DEFAULT_WIDTH = 10
  readonly WINDOW_DEFAULT_HEIGHT = 10
  readonly LAYOUT_SIMPLE_TEMPLATE = 'minmax(100px, 1fr) / repeat({{columns}}, 1fr)'
  readonly LAYOUT_GRID_TEMPLATE = `repeat(${this.GRID_HEIGHT}, 1fr) / repeat(${this.GRID_WIDTH}, 1fr)`

  @Input() controlRoom: boolean = true
  // @ViewChild(WindowHostViewDirective) windowHost!: WindowHostViewDirective
  // viewContainer!: ViewContainerRef

  private _layout: WindowLayoutMode = WindowLayoutMode.Simple
  layoutGridTemplate: string = ''

  constructor(
    private readonly roomService: RoomService,
    readonly injector: Injector,
    private readonly el: ElementRef<HTMLElement>,
    private readonly windowManager: WindowManagerService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    asyncScheduler.schedule(() => this.updateLayoutStyle())
  }

  ngOnDestroy() {
    this.windowManager.reset()
  }

  @Input()
  get layout(): WindowLayoutMode {
    return this._layout
  }

  set layout(val: WindowLayoutMode) {
    if (this._layout !== val) {
      this._layout = val
      this.updateLayoutStyle()
    }
  }

  get gridMode() {
    return this._layout === WindowLayoutMode.Grid
  }

  get simpleMode() {
    return this._layout === WindowLayoutMode.Simple
  }

  get topWindows() {
    return this.windowManager.windows.pipe(this.positionFilter(WindowPosition.DockTop))
  }

  get bottomWindows() {
    return this.windowManager.windows.pipe(this.positionFilter(WindowPosition.DockBottom))
  }

  get leftWindows() {
    return this.windowManager.windows.pipe(this.positionFilter(WindowPosition.DockLeft))
  }

  get rightWindows() {
    return this.windowManager.windows.pipe(this.positionFilter(WindowPosition.DockRight))
  }

  get normalWindows() {
    return this.windowManager.windows.pipe(this.positionFilter(WindowPosition.Normal, true))
  }

  get minimizedWindows() {
    return this.windowManager.windows.pipe(this.positionFilter(WindowPosition.Minimized))
  }

  get maximizedWindows() {
    return this.windowManager.windows.pipe(this.positionFilter(WindowPosition.Maximized))
  }

  get empty() {
    return this.windowManager.empty
  }

  get roomName() {
    return this.roomService.current.value?.name
  }

  private positionFilter(position: WindowPosition, showEmpty = false) {
    return map<WindowInfo[], WindowList>(
      list => {
        return {
          list: list.filter(w => w.window.position === position),
          showEmpty
        }
      }
    )
  }

  private updateLayoutStyle() {
    switch (this.layout) {
      case 'simple':
        const numWindows = Math.max(1, Math.min(3, this.windowManager.normal.length))
        this.layoutGridTemplate = this.LAYOUT_SIMPLE_TEMPLATE.replace('{{columns}}', numWindows.toString())
        break

      case 'grid':
        this.layoutGridTemplate = this.LAYOUT_GRID_TEMPLATE
        break
    }
  }

  getPointerGridPosition($event: PointerEvent) {
    return this.getGridPosition({x: $event.clientX, y: $event.clientY})
  }

  getGridPosition(pos: Position): Position | undefined {
    const rect = this.el.nativeElement.getBoundingClientRect()

    if (
      rect.left <= pos.x
      && rect.right > pos.x
      && rect.top <= pos.y
      && rect.bottom > pos.y
    ) {
      const x = Math.floor((pos.x - rect.left) / (rect.width / this.GRID_WIDTH));
      const y = Math.floor((pos.y - rect.top) / (rect.height / this.GRID_HEIGHT));

      return {x, y}
    }

    return undefined
  }

  protected readonly top = top;
}
