<img src="favicon.ico" alt="Logo" width="24" class="d-inline-block align-text-top pe-1">
<div class="fs-5">Liaisun</div>
<div class="flex-fill"></div>


<ng-container *ngIf="auth.authenticated | async; else notAuthenticated">
  <span class="navbar-text fs-5">{{auth.user!.name}}</span>
  <app-button icon="settings" (click)="showDevices()"></app-button>
  <app-button color="danger" [transparent]="false" text="Sign Out" (click)="auth.logout()"></app-button>
</ng-container>

<ng-template #notAuthenticated>
  <button
    type="button"
    class="btn btn-primary btn-sm"
    (click)="auth.login()">
    Sign In
  </button>
</ng-template>
