import {Injectable} from "@angular/core";
import {Feature} from "./feature";
import {WindowComponentClass} from "../windows/window-info";
import {ConferenceWindowComponent} from "../conference/conference-window/conference-window.component";
import {ConferenceGalleryComponent} from "../conference/conference-gallery/conference-gallery.component";
import {ShareWindowComponent} from "../conference/share-window/share-window.component";
import {
  ConferenceFeatureButtonsComponent
} from "../conference/conference-feature-buttons/conference-feature-buttons.component";
import {ConferenceService} from "../conference/conference.service";
import {FeatureModel} from "../api/models/feature.model";
import {asyncScheduler, lastValueFrom} from "rxjs";
import {ShareManagerService} from "../conference/share-manager.service";
import {WindowPosition} from "../api/models/window.model";
import {Logging} from "../common/logging";

export enum ConferenceMode {
  Speaker = 'speaker',
  Gallery = 'gallery',
  Docked = 'docked',
  Share = 'share'
}

@Injectable({ providedIn: 'root' })
export class ConferenceFeature extends Feature {
  readonly type = 'conference'
  readonly name = 'Conference'
  override readonly controlsComponent = ConferenceFeatureButtonsComponent

  private readonly logger = Logging.for('conference', 'conference-feature')

  constructor(
    private readonly conference: ConferenceService,
    private readonly shareManager: ShareManagerService
  ) {
    super()
  }

  get mode(): ConferenceMode {
    return this.state.mode as ConferenceMode
  }

  set mode(mode) {
    if (mode !== this.mode) this.updateState({mode})
  }

  get secure(): boolean {
    return this.state.secure
  }

  get sharing(): boolean {
    return this.state.sharing
  }

  set sharing(sharing) {
    if (sharing !== this.sharing) this.updateState({sharing})
  }

  get mainWindowVisible() {
    return this.mainWindow.position !== WindowPosition.Hidden
  }

  override async enable(state: FeatureModel): Promise<void> {
    await super.enable(state)
    this.subscriptions.add(this.shareManager.changed, count => this.onScreenSharesChanged(count))

    this.logger.debug('Requesting initial join')
    await lastValueFrom(this.conference.join(false), {defaultValue: null})

    asyncScheduler.schedule(() => {
      this.logger.debug('Requesting join with media')
      this.conference.join(true)
    })
  }

  override async disable(): Promise<void> {
    await lastValueFrom(this.conference.leave(), {defaultValue: null})
    await super.disable()
  }

  override componentForWindow(name: string): WindowComponentClass {
    switch (name) {
      case 'gallery':
        return ConferenceGalleryComponent
      case 'share':
        return ShareWindowComponent
      default:
        return ConferenceWindowComponent
    }
  }

  get mainWindow() {
    return this.window('main')!
  }

  // get galleryWindow() {
  //   return this.window('gallery')!
  // }
  //
  // get shareWindow() {
  //   return this.window('share')!
  // }

  private onScreenSharesChanged(count: number) {
    this.sharing = count > 0
  }

  // private updateWindows() {
  //   if (this.mode === ConferenceMode.Share) {
  //     // Do nothing
  //   } else if (this.hasShares) {
  //     this.shareWindow.position = WindowPosition.Normal
  //     this.mainWindow.position = WindowPosition.Hidden
  //     this.galleryWindow.position = WindowPosition.DockRight
  //   } else {
  //     this.shareWindow.position = WindowPosition.Hidden
  //     this.mainWindow.position = WindowPosition.Normal
  //
  //     if (this.mode === ConferenceMode.Normal) {
  //       this.galleryWindow.position = WindowPosition.DockRight
  //     } else {
  //       this.galleryWindow.position = WindowPosition.Hidden
  //     }
  //   }
  // }

  protected override onStateChange(state: any) {
    // this.updateWindows()
  }
}
