<div class="mb-5 fs-3">
  <ng-container *ngIf="selectedDevices; else noDevices">
    <pre>{{ selectedDevices | json }}</pre>
  </ng-container>
  <ng-template #noDevices>
    No Devices selected
  </ng-template>
</div>

<button type="button" class="btn btn-primary" (click)="prompt()">Select Devices</button>
