<div
  #modalContent
  class="app-modal-container
  position-absolute
  start-50 translate-middle-x
  text-bg-dark bg-opacity-100
  rounded rounded-3 overflow-hidden shadow
  border border-{{modal.style}} border-5 border-opacity-25
  mt-5"
  style="top: -500px"
>
  <div class="app-modal-heading text-bg-{{modal.style}} fs-4 px-2 py-2" *ngIf="title">
    {{title}}
  </div>

  <div class="app-modal-body p-4 bg-black">
    <ng-container
      [ngComponentOutlet]="modal.component"
      [ngComponentOutletInputs]="modal.inputs"
    ></ng-container>
  </div>

  <div class="app-modal-footer d-flex-row align-items-center justify-content-center gap-2 p-2 bg-black bg-opacity-50">
    <ng-container *ngFor="let $button of modal.buttons">
      <button
        type="button"
        class="btn btn-{{$button.style || 'secondary'}}"
        (click)="onButtonClick($button)"
        [disabled]="disableButton($button)"
      >{{$button.text}}</button>
    </ng-container>
  </div>
</div>
