import {Component, OnDestroy} from "@angular/core";
import {Subscriptions} from "./subscriptions";

@Component({ template: '', standalone: true })
export class ComponentWithSubscriptions implements OnDestroy {
  protected readonly subscriptions = new Subscriptions()

  ngOnDestroy() {
    this.subscriptions.clear()
  }
}
