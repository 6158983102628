import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AcquireOptions, DeviceInfo, DeviceService, DeviceType} from "../device.service";
import {asyncScheduler, BehaviorSubject, concat, first, switchMap, timer} from "rxjs";
import {AcquiredDevices} from "../acquired-devices";
import {ModalConfig} from "../../common/modal/modal";
import {ModalComponent} from "../../common/modal/modal.component";
import {Subscriptions} from "../../common/subscriptions";

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent extends ModalComponent implements OnDestroy, OnInit {
  static readonly modalConfig: ModalConfig = {
    title: 'Select Devices',
    buttons: ['ok', 'cancel']
  }

  protected readonly _cameras = new BehaviorSubject<DeviceInfo[]>([])
  protected readonly _microphones = new BehaviorSubject<DeviceInfo[]>([])
  protected disableCamera = false
  protected disableMicrophone = false
  protected microphoneLevel = 0

  private readonly subscriptions = new Subscriptions()
  private acquiredDevices?: AcquiredDevices
  private _selectedCamera?: DeviceInfo
  private _selectedMicrophone?: DeviceInfo
  private initialized = false

  @ViewChild('cameraVideo') protected cameraVideo?: ElementRef<HTMLVideoElement>

  constructor(
    private readonly deviceService: DeviceService
  ) {
    super()
  }

  protected get microphones() {
    return this._microphones.asObservable()
  }

  protected get cameras() {
    return this._cameras.asObservable()
  }

  protected get selectedCamera() {
    return this._selectedCamera
  }

  protected set selectedCamera(device) {
    this._selectedCamera = device
    this.start()
  }

  protected get selectedMicrophone() {
    return this._selectedMicrophone
  }

  protected set selectedMicrophone(device) {
    this._selectedMicrophone = device
    this.start()
  }

  ngOnInit() {
    this.subscriptions.add(
      concat(
        timer(100),
        this.deviceService.deviceChange
      ).pipe(
        switchMap(() => this.deviceService.devices().pipe(first(undefined, []))),
      ),
      list => asyncScheduler.schedule(() => this.updateDevices(list))
    )
  }

  ngOnDestroy() {
    this.subscriptions.clear()
    this.stop()
  }

  override onButtonClick(action: any) {
    if (action === 'ok') {
      this.stop()
      this.close(this.result)
    }
    return false
  }

  override canClick(action: any): boolean {
    return this.initialized
  }

  private start() {
    this.stop()

    this.subscriptions.set('acquire', this.deviceService.acquire(this.result), devices => {
      if (devices) this.setupTest(devices)
    })
  }

  private stop() {
    this.subscriptions.remove('microphoneLevel')
    this.microphoneLevel = 0
    if (this.cameraVideo?.nativeElement) this.cameraVideo.nativeElement.srcObject = null
    this.acquiredDevices?.close()
    delete this.acquiredDevices
  }

  private updateDevices(list: DeviceInfo[]) {
    const cameras = list.filter(d => d.type === DeviceType.Camera)
    const microphones = list.filter(d => d.type === DeviceType.Microphone)

    this._cameras.next(cameras)
    this._microphones.next(microphones)

    this._selectedCamera = (
      this._selectedCamera
        ? cameras.find(it => it.id === this._selectedCamera?.id)
        : undefined
    ) || cameras[0]

    this._selectedMicrophone = (
      this._selectedMicrophone
        ? microphones.find(it => it.id === this._selectedMicrophone?.id)
        : undefined
    ) || microphones[0]

    this.initialized = true
    this.start()
  }

  private setupTest(devices: AcquiredDevices) {
    this.acquiredDevices = devices
    if (devices.video) {
      this.cameraVideo!.nativeElement!.srcObject = devices.stream
    }
    if (devices.microphone) {
      this.subscriptions.set(
        'microphoneLevel',
        devices.audioActivity,
        info => {
          this.microphoneLevel = info.level
        }
      )
    }
  }

  private get result(): AcquireOptions {
    const options: AcquireOptions = {}

    if (this.selectedCamera) options.video = {id: this.selectedCamera.id}
    if (this.selectedMicrophone) options.microphone = {id: this.selectedMicrophone.id}

    return options
  }
}
