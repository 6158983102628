<ng-container *ngIf="participant || capture">
  <app-annotations #annotations appFullScreen [showMasks]="conference.secure && !!capture">
    <ng-template #enterFullScreen>
      <app-button
        icon="enter-fullscreen"
        title="Full Screen"
        (click)="annotations.fullScreen.on()"
      ></app-button>
    </ng-template>

    <ng-template #exitFullScreen>
      <app-button
        icon="exit-fullscreen"
        title="Exit Full Screen"
        (click)="annotations.fullScreen.off()"
      ></app-button>
    </ng-template>

    <ng-template #captureControls>
      <app-button icon="close" (click)="stop()" title="Stop Sharing"></app-button>
    </ng-template>

    <ng-template #sharedControls>
    </ng-template>

    <ng-template #video>
      <video
        [srcObject]="videoStream | async"
        autoplay
        appDrawingTarget
        class="fill object-fit-contain"
        (ended)="closeWindow()"
      ></video>
    </ng-template>

    <ng-template #videoCanvas>
      <app-video-canvas
        [videoSource]="videoStream | async"
        class="mw-100 mh-100"
        (ended)="closeWindow()"
        (output)="capture!.setOutput($event)"
      ></app-video-canvas>
    </ng-template>

    <ng-container *ngIf="capture && conference.secure; then videoCanvas; else video"></ng-container>

    <ng-container controls>
      <ng-container *ngIf="annotations.fullScreen.enabled | async; then exitFullScreen; else enterFullScreen"></ng-container>
      <ng-container *ngIf="capture; then captureControls; else sharedControls"></ng-container>
    </ng-container>
  </app-annotations>
</ng-container>
