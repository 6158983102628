import {Component, Input} from '@angular/core';
import {NewRoomComponent} from "../new-room/new-room.component";
import {RoomModel} from "../../api/models/room.model";
import {ModalService} from "../../common/modal/modal.service";
import {ErrorService} from "../../common/error.service";
import {Router} from "@angular/router";
import {RoomTypeDef} from "../room-type";

@Component({
  selector: 'app-new-room-button',
  templateUrl: './new-room-button.component.html',
  styleUrls: ['./new-room-button.component.scss'],
  host: {
    class: 'd-content'
  }
})
export class NewRoomButtonComponent {
  @Input() roomType!: RoomTypeDef

  constructor(
    private readonly modal: ModalService,
    private readonly error: ErrorService,
    private readonly router: Router
  ) {
  }

  createRoom() {
    this.modal.show({
      component: NewRoomComponent,
      inputs: {roomType: this.roomType}
    }).subscribe((action: any) => {
      this.goToRoom(action)
    })
  }

  goToRoom(room: RoomModel) {
    this.error.handlePromise(
      this.router.navigate(['/room', room.id])
    )
  }

  get backgroundUrl() {
    return [
      'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.6) 35%,rgba(0,0,0,0.6) 65%,rgba(0,0,0,0))',
      `url("assets/room/${this.roomType.image}")`
    ].join(',')
  }
}
